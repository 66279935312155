import React, { useEffect, useState } from 'react'
import static_data from './static_data'
import './Specialization_Dpi.css'
import axios from 'axios'
import ProposalForm from '../../ProposalForm'
import ExpandedCard from './ExpandedCard'
import { toast, ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";

const SpecializationDpi = ({clientType, special, subSpecial, sumInsured, terri, prospect, opd, ipd}) => {
    const [buyType, setBuyType] = useState('Individual Practice')
    const [specialization, setSpecialization] = useState('')
    const [opdValue, setOpdValue] = useState()
    const [ipdValue, setIpdValue] = useState()
    const [subSpecialization, setSubSpecialization] = useState('')
    const [step, setStep] = useState(4)
    const [limitOfIndemnity, setLimitOfIndemnity] = useState('')
    const [territory, setTerritory] = useState('')
    const [premium, setPremium] = useState('')
    const [prospectId, setProspectId] = useState('')
    const [quoteId, setQuoteId] = useState('')
    const [calculatorData, setCalcData] = useState({})
    const [showExpandedCard, setShowExpandedCard] = useState(false)
    const [legalPremium, setLegalPremium] = useState()
    const [disableMailBtn, setDisableMailBtn] = useState(false)

    useEffect(()=>{
        setProspectId(prospect)
        setBuyType(clientType)
        setSpecialization(special)
        setSubSpecialization(subSpecial)
        setLimitOfIndemnity(sumInsured)
        setTerritory(terri)
        setOpdValue(opd)
        setIpdValue(ipd)
        nextStep(clientType, special, subSpecial, sumInsured, terri, opd, ipd)
    },[])

    const nextStep = (clientType, special, subSpecial, sumInsured, terri, opd, ipd) => {
        const legal_prem = JSON.parse(localStorage.getItem("legal_premium"))
        setLegalPremium(legal_prem.premiumExclGST)
        const url = '/calc/doctorProfessionalIndemnityCalc17Dec2021'
        const data = {
            'XLEW_4_4_3': clientType ? clientType : buyType,
            'xlew_4_4_4': '',
            '_Ctrl_3': special ? special : specialization? specialization : "0",
            'xlew_4_6_4': '',
            'XLEW_4_8_3': subSpecial ? subSpecial : subSpecialization,
            'XLEW_4_10_3': 0,
            'XLEW_4_12_3': 0,
            '_Ctrl_6': sumInsured ? sumInsured : limitOfIndemnity,
            'XLEW_5_3_4': terri ? terri : territory,
            'XLEW_5_5_4': 'United India Insurance Company Ltd.',
            'XLEW_5_13_2': 'Loss of Documents',
            'XLEW_5_14_2': 'Libel & Slander',
            'XLEW_5_15_2': 'Defamation',
            'XLEW_5_16_2': 'Breach of Confidential Information',
            'XLEW_5_17_2': 'Pre-Litigation Cost',
            'XLEW_5_20_4': 'Covered'
        }
        axios.post(process.env.REACT_APP_PAM_URL + url, data).then((res) => {
            const premiumVal = res.data.XLEW_5_6_4 = typeof (res.data.XLEW_5_6_4) === 'number' ? res.data.XLEW_5_6_4.toFixed(0) : res.data.XLEW_5_6_4;
            setCalcData(res.data)
            setPremium(premiumVal)
            saveQuoteData(res.data, clientType, special, subSpecial, sumInsured, terri, prospect, opd, ipd, 'unitedIndia')
        }).catch((err) => console.log(err))
    }

    const saveQuoteData = (calulatorData, clientType, special, subSpecial, sumInsured, terri, prospect, opd, ipd) => {
        const data = {
            "buyingFor": clientType? clientType : buyType,
            "specialization": special? special : specialization? specialization : "0",
            "subSpecialization": subSpecial? subSpecial : subSpecialization,
            "opdCase": (clientType === 'Hospital/Nursing/Polyclinic' ||  buyType === 'Hospital/Nursing/Polyclinic') ? opd?opd: opdValue : 0,
            "ipdCase":(clientType === 'Hospital/Nursing/Polyclinic' ||  buyType === 'Hospital/Nursing/Polyclinic') ? ipd?ipd : ipdValue : 0,
            "sumAssured0": sumInsured? sumInsured : limitOfIndemnity,
            "territoryAndJurisdiction": terri ? terri : territory,
            "radioActive0": 'covered',
            "insurerLogo": 'United India Insurance Company Ltd.',
            "netPremium": calulatorData.XLEW_5_6_4,
            "jurisdiction": terri? terri : territory,
            "excessIndia": calulatorData.XLEW_5_9_4,
            "prospectId": prospect? prospect : prospectId ? prospectId : '',
            "quoteId": quoteId,
            "loggedInUserId": ''
        }
        let url = '/profession/api/v1/saveDoctorProfessionalIndemnityData'
        axios.post(process.env.REACT_APP_WEB_PAM_URL + url, data).then(res => {
            localStorage.setItem("QuoteId", res.data.data)
            setQuoteId(res.data.data)
        }).catch(err => {
            console.log(err.message)
        })
    }

    const redirectToProposal=()=>{
        setStep(5)
        setShowExpandedCard(false)
    }

    const viewExpandedQuoteCard=()=>{
        setShowExpandedCard(true)
    }

    const hideExpandedCard=()=>{
        setShowExpandedCard(false)
        setStep(4)
    }

    const downloadPdf=()=>{
        var url = process.env.REACT_APP_WEB_PAM_URL + '/profession/api/v1/DoctorProfessionalIndemnity/downloadDoctorPIPDF/'
        window.open(url+quoteId)
    }

    const sendEmail=()=>{
        var url = process.env.REACT_APP_WEB_PAM_URL + '/profession/api/v1/DoctorProfessionalIndemnity/sendDoctorPIPDFMail/'
        axios.get(url+quoteId).then((res)=>{
            toast.success("A detailed quote has been emailed to your registered email id", {
                position: toast.POSITION.TOP_CENTER
            });
            setDisableMailBtn(true) 
        }).catch((err)=>{
            toast.error(err.response.data.message,{
                position: toast.POSITION.TOP_CENTER
            })
            setDisableMailBtn(false)
        })
    }

    const calculateTotal=()=>{
        const finalVal = parseInt(legalPremium) + parseInt(premium)
        console.log(finalVal)
        return finalVal
    }

    return (
        <>
        {step !=5 && !showExpandedCard ?
        <div className="specialization-form">
            <div className="text-center">
                <img src={'https://static-files-website.s3.ap-south-1.amazonaws.com/securenow_logo.svg'} alt="" style={{ padding: "20px 0" }} />
            </div>
            <div className="container">
                <div className="user-details">
                    {
                        <>
                        <div className="user-details-container">
                            <div className='row quote-card pt-3'>
                                <div className='col-md-4 col-lg-4 col-sm-4 col-xs-4'>
                                    <img src='assets/img/avatars/unitedIndiaNewLogo.webp' height="50px" width="70px" />
                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-4 col-xs-4'>
                                    <p className='left-text'>Premium<br />(Excluding Taxes)</p>
                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-4 col-xs-4'>
                                    <p className='amount'><i className='fa fa-inr'></i><span className='pl-2'>{premium !=0 || !premium ? premium :'NA'}</span></p>
                                </div>
                            </div>
                                <div className='row quote-card pt-4'>
                                    <div className='col-md-5 col-lg-5 col-sm-6 col-xs-6'>
                                        <p className='left-text'>Claim Settlement Ratio</p>
                                    </div>
                                    <div className='col-md-7 col-lg-7 col-sm-6 col-xs-6'>
                                        <p className='left-text'>80.70%</p>
                                    </div>
                                </div>
                                <div className='row quote-card pt-2'>
                                    <div className='col-md-5 col-lg-5 col-sm-6 col-xs-6'>
                                        <p className='left-text'>Policy Turnaround Time</p>
                                     </div>
                                    <div className='col-md-7 col-lg-7 col-sm-6 col-xs-6'>
                                        <p className='left-text'>15 Days</p>
                                    </div>
                                </div>
                                <div className='row quote-card pt-2'>
                                    <div className='col-md-5 col-lg-5 col-sm-6 col-xs-6'>
                                        <p className='left-text'>Excess: India</p>
                                    </div>
                                    <div className='col-md-7 col-lg-7 col-sm-6 col-xs-6'>
                                        <p className='left-text'>{calculatorData.XLEW_5_9_4}</p>
                                    </div>
                                </div>
                                <div className='row quote-card mt-5 mb-2'>
                                    <div className='col-md-5 col-lg-5 col-sm-6 col-xs-6'>
                                        <p className='bottom-text'>28% doctors took in last 5 days</p>
                                    </div>
                                    <div className='col-md-7 col-lg-7 col-sm-6 col-xs-6'>
                                        <button className='btn1' onClick={()=>viewExpandedQuoteCard()}>View Details</button>
                                    </div>
                                </div>
                                <span className='text-danger text-center d-block' style={{fontSize:'30px', fontWeight:'700'}}><b>+</b></span>
                                <div className='row'>
                                    <div className='col-md-12 col-lg-12 col-sm-12 col-xs-12'>
                                        {legalPremium && <span className='d-block'>Your Legal Subscription Amount (Excl. GST) : <strong><i className='fa fa-inr'></i>&nbsp;{legalPremium}</strong></span>}
                                    </div>
                                </div> 
                                <hr/>
                                <div className='row pt-2 mb-3'>
                                    <div className='col-md-9 col-lg-9 col-sm-6 col-xs-6'>
                                        <span>Total Amount (Excl. GST) : <b><i className='fa fa-inr'></i></b> <strong>{calculateTotal()}</strong></span>
                                    </div>
                                    <div className='col-md-3 col-lg-3 col-sm-6 col-xs-6'>
                                        <button disabled={premium ==0 || !premium ? true : false} className='pay-btn' onClick={(e)=>redirectToProposal(e)}>Proceed To Pay</button>
                                    </div>
                                </div>
                            </div>
                        </>}
                </div>
            </div>
        </div>:
        
        showExpandedCard ?
            <ExpandedCard
                title1="Policy Benefits"
                title2="Conditions"
                calulatorData={calculatorData}
                coversOptedData={static_data.coversOptedData}
                conditionData={static_data.conditionData}
                hideExpandedCard={()=>hideExpandedCard()}
                downloadPdf={()=>downloadPdf()}
                sendEmail={()=>sendEmail()}
                premium={premium !=0 || !premium ? premium :'NA'}
                disableMailBtn={disableMailBtn}
                redirectToProposal={()=>redirectToProposal()}
            /> :

        <ProposalForm details={true} dpiPremium={premium}/>}
        <ToastContainer autoClose={8000} />
        </>
    )
}

export default SpecializationDpi