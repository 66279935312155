const static_data = {
    specializationList : [
        { ind: 1, name: 'Anesthetist', id: 'Anesthetist' },
        { ind: 4, name: 'Cosmetic Surgeons', id: 'Cosmetic Surgeons' },
        { ind: 3, name: 'Dentist', id: 'Dentist' },
        { ind: 5, name: 'Eye Specialist', id: 'Eye Specialist' },
        { ind: 6, name: 'General Physician', id: 'General Physician' },
        { ind: 7, name: 'Lithotripsy', id: 'Lithotripsy' },
        { ind: 8, name: 'Maxillo Facial Surgeon/Dermatologist', id: 'Maxillo Facial Surgeon/Dermatologist' },
        { ind: 9, name: 'Non Surgeons', id: 'Non Surgeons' },
        { ind: 10, name: 'Obstetrician', id: 'Obstetrician' },
        { ind: 11, name: 'Pathologist', id: 'Pathologist' },
        { ind: 12, name: 'Plastic Surgeon', id: 'Plastic Surgeon' },
        { ind: 13, name: 'Radiologist', id: 'Radiologist' },
        { ind: 14, name: 'Specialist/Consultants', id: 'Specialist/Consultants' },
        { ind: 15, name: 'Surgeons', id: 'Surgeons' },
    ],

    subSpecializationAnesthetistList : [
          
        { ind: 0, name: 'Critical care medicine with Anesthesia', id: 'Critical care medicine with Anesthesia' },
        { ind: 1, name: 'Critical care medicine without Anesthesia', id: 'Critical care medicine without Anesthesia' },
    ],

    subSpecializationRadiologist : [

       
        { ind: 0, name: 'Consultant Radiologist', id: 'Consultant Radiologist' },
        { ind: 1, name: 'Interventional Radiologist', id: 'Interventional Radiologist' },
        { ind: 2, name: 'Neurologist Radiologist', id: 'Neurologist Radiologist' },
        { ind: 3, name: 'Nuclear Medicine Radiologist', id: 'Nuclear Medicine Radiologist' },
        { ind: 4, name: 'Radiologist Oncologist', id: 'Radiologist Oncologist' },
        { ind: 5, name: 'Tele Radiologist', id: 'Tele Radiologist' },
        { ind: 6, name: 'Radiologist, MBBS', id: 'Radiologist, MBBS' },
    ],

    subSpecializationPathologist : [

        { ind: 0, name: 'Pathologist, MBBS', id: 'Pathologist, MBBS' },
        { ind: 1, name: 'Other Pathologists', id: 'Other Pathologists' },
    ],

    subSpecializationPGeneralPhysician : [

        { ind: 0, name: 'General Physician, MBBS', id: 'General Physician, MBBS' },
        { ind: 1, name: 'Other General Physicians', id: 'Other General Physicians' },
    ],

    limitOfIndemnityList : [
        { ind: 0, name: '10 Lacs', id: '10 Lacs' },
        { ind: 1, name: '20 Lacs', id: '20 Lacs' },
        { ind: 2, name: '30 Lacs', id: '30 Lacs' },
        { ind: 3, name: '50 Lacs', id: '50 Lacs' },
        { ind: 4, name: '70 Lacs', id: '70 Lacs' },
        { ind: 5, name: '1 Crore', id: '1 Crore' },
        { ind: 6, name: '2 Crore', id: '2 Crore' },
        { ind: 7, name: '3 Crore', id: '3 Crore' },
        { ind: 8, name: '4 Crore', id: '4 Crore' },
        { ind: 9, name: '5 Crore', id: '5 Crore' },
    ],

    limitOfIndemnityHospitalList : [
        { ind: 0, name: '30 Lacs', id: '30 Lacs' },
        { ind: 1, name: '50 Lacs', id: '50 Lacs' },
        { ind: 2, name: '70 Lacs', id: '70 Lacs' },
        { ind: 3, name: '1 Crore', id: '1 Crore' },
        { ind: 4, name: '2 Crore', id: '2 Crore' },
        { ind: 5, name: '3 Crore', id: '3 Crore' },
        { ind: 6, name: '4 Crore', id: '4 Crore' },
        { ind: 7, name: '5 Crore', id: '5 Crore' },
        { ind: 8, name: '10 Crore', id: '10 Crore' },
    ],

    territoryAndJusictionList : [
        { ind: 0, name: 'India', id: 'India' },
        { ind: 1, name: 'Worldwide excluding US/Canada', id: 'Worldwide excluding US/Canada' },
        { ind: 2, name: 'Worldwide', id: 'Worldwide' },
    ],

    coversOptedData: [
        {
            id: 0,
            data: "Limit of Indemnity (LOI)",
            value: {
                quoteKey: "_Ctrl_6",
            },
            toolTip: "The maximum amount an insurer will pay for a covered loss."
        },
        {
            id: 1,
            data: "Any One Accident : Any One Year (AOA: AOY)",
            value: {
                data: "1:1"
            },
            toolTip: "AOA(Any One Accident): AOY(Any One Year) limit defines the ratio which determine the limit of indemnity payable to the insured at the time of claim. For Example 1:2 implies that for any claim, the maximum liablity you can claim is half of the insured amount."
        },
        {
            id: 2,
            data: "Claim Settlement Ratio",
            value: {
                data: "80.70%",
            },
        },
        {
            id: 3,
            data: "Policy Turnaround Time",
            value: {
                data: "15 Days",
            },
        },
        {
            id: 4,
            data: "Excess: India",
            value: {
                quoteKey: "XLEW_5_9_4"
            }
        },
        {
            id: 5,
            data: "Territory & Jurisdiction",
            value: {
                quoteKey: "XLEW_5_3_4"
            },
            toolTip: "The reigon where claims are covered"
        },
        {
            id: 6,
            data: "Defence cost",
            value: {
                data: "Covered on reimbursement basis"
            },
            toolTip: "Covers cost of defending against a lawsuit. Includes cost of lawyers, court fees, investigations and filings"
        },
        {
            id: 7,
            data: "Loss of Documents",
            value: {
                quoteKey: "XLEW_5_13_4"
            }
        },
        {
            id: 8,
            data: "Libel & Slander",
            value: {
                quoteKey: "XLEW_5_14_4"
            }
        },
        {
            id: 9,
            data: "Defamation",
            value: {
                quoteKey: "XLEW_5_15_4"
            }
        },
        {
            id: 11,
            data: "Breach of Confidential Information",
            value: {
                quoteKey: "XLEW_5_16_4"
            }
        },
        {
            id: 12,
            data: "Out of court settlement",
            value: {
                data: "Covered Upto Rs. 5,00,000"
            },
        },
        {
            id: 13,
            data: "Support Staff (unskilled)",
            value: {
                data: "Covered"
            },
        },
    ],

    conditionData: [
        {
            id: 1,
            data: "Basis of Coverage",
            value: {
                data: "Claims made policy"
            },
        },
        {
            id: 2,
            data: "Retroactive Date for Renewal Cases",
            value: {
                quoteKey: "XLEW_5_24_4"
            },
        },
        {
            id: 3,
            data: "Filled-Up Proposal Form",
            value: {
                data: "covered"
            },
        },
        {
            id: 4,
            data: "No known or reported losses in last 5 years",
            value: {
                data: "covered"
            },
        },
    ],

    Std_clause: [
        "Administering euthanasia/ abetting suicide",
        "Criminal abortions with illegal methods/ techniques",
        "Misbehaviour- unethical acts with patients",
        "Deliberate malpractices like false certificates, falsification of clinical records, Except the allegations there too",
        "Collusion or direct involvement in cold-blooded murder",
        "Divorce suits as co-defandants",
        "Staff problems/ labor disputes(employer-employee relationship)",
        "Administration of spurious/ prohibited drugs",
        "Illegal dealings in narcotics and other drugs declared as contraband but the appropriate authority from time to time",
        "Trade and dealings in live organs for transplant",
        "Illegal stocking or dispensing, prescription of prohibited drugs, unauthorized/illegal experimentation and procedures",
        "Practice without valid registration / certificate",
        "Contravention of Govt. Acts, Corporation/Municipal regulations in matters of practice, running establishments,licensing, display of boards, food facilities to patients",
        "Unethical and/or illegal practice in fertility and impotence, etc.",
        "Cosmetics surgery done for / in collusion with criminals",
        "Falsification/ substitution of death certificates cause of death/ change of cadavers",
        "Collusion in substitution of babies",
    ]
}
export default static_data