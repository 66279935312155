import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from "reactstrap";
import logo from "../../../../../src/assets/img/brand/securenow-logo.png";
import { resetPassword } from "../../../../Shared/ApiConstants";

class ResetPassword extends Component {

    state = {
        password: '',
        confirmPassword: '',
        key: '',
        errorMsg:"",
        successMsg:"",
        color:"red",
        logout:false,
    }
    componentDidMount() {
        var key = window.location.href.split("=")[1]
        if(localStorage.getItem("resetPasswordKey") && localStorage.getItem("resetPasswordKey")==key){
            this.setState({
                logout:true
            })
        }else{
        this.setState({
            key
        })}
    }
    handelChange = (e) => {
        this.setState({
            errorMsg:"",
            [e.target.name]: e.target.value
        })
    }
    resetPassword = (e) => {
        e.preventDefault()
        if(this.state.password.length<6){
            this.setState({
                errorMsg:"Password length should be greater than or eqault to 6 characters"
            })
        }else if(this.state.password!==this.state.confirmPassword){
            this.setState({
                errorMsg:"Passwords don't match"
            })
        }else{
            this.setState({
                color:"green"
            })
            const objData = {
                newPassword: this.state.password,
                key: this.state.key
            }
            axios.post(resetPassword, objData).then(res => {
                if(res.status==200){
                    this.setState({
                      errorMsg:"New Password created successfully",
                      successMsg:"You will be redirected to Login Page in few seconds"
                    },()=>{
                        localStorage.setItem("resetPasswordKey",this.state.key)
                        this.countdownTimer()
                    })
                }
            });
        }
    }
    countdownTimer=()=>{
       setTimeout(()=>{
           this.setState({
               logout:true
           })
       },3000)
    }
    render() {
        if (this.state.logout) {
            return (
                <Redirect
                    to={{
                        pathname: ``
                    }}
                />
            );
        } else {
            return (
                <div className="flex-row align-items-center">
                    <Container>
                        <img src={'https://static-files-website.s3.ap-south-1.amazonaws.com/securenow_logo.svg'} alt="" style={{ padding: "20px 0" }} />
                        <Row className="justify-content-center p-5">
                            <Col >
                                <Card style={{ border: "0px", width: "60%", margin: "0 0 0 21%", textAlign: "center" }}>
                                    <CardBody className="p-5">
                                        <Form className="form" onSubmit={this.resetPassword}>
                                            <h2><b className="text-center" >Reset Password</b></h2>
                                            <br />
                                            <h6>Please enter your new password for your Partner Platform account</h6>
                                            <br />

                                            <h6 style={{ fontWeight: "600", textAlign: "initial" }}>New password</h6>
                                            <InputGroup className="mb-3">
                                                <Input
                                                    required
                                                    name="password"
                                                    type="password"
                                                    value={this.state.password}
                                                    onChange={this.handelChange}
                                                />
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-lock"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>

                                            <h6 style={{ fontWeight: "600", textAlign: "initial" }}>Confirm New password</h6>
                                            <InputGroup className="mb-3">
                                                <Input
                                                    required
                                                    name="confirmPassword"
                                                    type="password"
                                                    value={this.state.confirmPassword}
                                                    onChange={this.handelChange}
                                                />
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText>
                                                        <i className="icon-lock"></i>
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                            </InputGroup>

                                            <Row>
                                                <Col>
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="px-4"
                                                    >
                                                        Change my Password
                                                </Button>
                                                </Col>
                                            </Row>
                                            <br></br> <span style={{ color: this.state.color, alignItems: 'center' }}>{this.state.errorMsg}</span>
                                            <br></br> <span style={{ color: "green", alignItems: 'center' }}>{this.state.successMsg}</span>


                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    </Container>

                </div>
            )
        }
    }
}

export default ResetPassword
