import React from "react";
import { Input, FormGroup, Label } from "reactstrap";

const InputTextField = ({
  name,
  label,
  placeholder,
  required,
  handleChange,
  className,
  type,
  inputId,
  disable,
  prefilled,
  maxLength,
  Style
}) => (
  <FormGroup>
    <Label style={Style || null} className={className}>{label}</Label>
    <Input
      id={inputId}
      type={type}
      name={name}
      placeholder={placeholder}
      disabled = {disable === 'SYSTEM'}
      value= {(disable === 'SYSTEM') ? prefilled : null}
      required= {(required === 'MANDATORY') ? true : false}
      onChange={handleChange}
      autoComplete="off"
      maxLength={maxLength}
    />
  </FormGroup>
);

export default InputTextField;
