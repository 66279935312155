import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
// import 'react-app-polyfill/ie11'; // For IE 11 support
import "./polyfill";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as axios from "axios";

// axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
// const AUTH_TOKEN = localStorage.getItem("auth-Token");
// console.log("in index", AUTH_TOKEN);
// axios.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
// axios.defaults.headers.post["Content-Type"] =
//   "application/x-www-form-urlencoded";

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
