import { Modal, ModalBody } from 'reactstrap'
import React, { useState } from 'react'
import './Specialization_Dpi.css'
import static_data from './static_data'

const ExpandedCard = ({ premium, title1, title2, calulatorData, coversOptedData, conditionData, hideExpandedCard, redirectToProposal, downloadPdf, sendEmail, disableMailBtn }) => {

    const [showExcl, setShowExcl] = useState(false)

    const showCalculatorData = (item) => {
        if (item.data) {
            return item.data;
        } else if (item.quoteKey) {
            return calulatorData[item.quoteKey]
        }
    }

    const showStandardExcl = () => {
        setShowExcl(true)
    }

    const closeStandardExcl = () => {
        setShowExcl(false)
    }

    return (
        <div className='specialization-form' style={{ height: 'auto',}}>
            <div className="text-center">
                <img src={'https://static-files-website.s3.ap-south-1.amazonaws.com/securenow_logo.svg'} alt="" style={{ padding: "20px 0" }} />
            </div>
            <div className="container">
                <div className='row' style={{ width: '100%' }}>
                    <div className='col-12'>
                        <div className='bg-white rounded p-4'>
                            <div className='row quote-card' style={{width:'55%'}}>
                                <div className='col-md-4 col-lg-4 col-sm-4 col-xs-4'>
                                    <img src='assets/img/avatars/unitedIndiaNewLogo.webp' height="50px" width="70px" />
                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-4 col-xs-4'>
                                    <p className='left-text'>Premium<br />(Excluding Taxes)</p>
                                </div>
                                <div className='col-md-4 col-lg-4 col-sm-4 col-xs-4'>
                                    <p className='amount'><i className='fa fa-inr'></i><span className='pl-2'>{premium}</span></p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-7'>
                                    <div className="quote-card-details quote-card">
                                        <div className="heading">{title1}</div>
                                        {coversOptedData.map((item) => {
                                            return (
                                                <div className="row align-items-center" key={item.id}>
                                                    <div className="col-md-5 col-sm-5 col-5 left-text">
                                                        <span> {item.data}</span>
                                                    </div>
                                                    <div className="col-md-7 col-sm-7 col-7 left-text">
                                                        <span>{showCalculatorData(item.value)}</span>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                                <div className='col-5 quote-card'>
                                    <div className="quote-card-details">
                                        <div className="heading">{title2}</div>
                                        {conditionData.map((item) => {
                                            return (
                                                <div className="row align-items-center" key={item.id}>
                                                    <div className="col-md-5 col-sm-5 col-5 left-text">
                                                        <span> {item.data}</span>
                                                    </div>
                                                    <div className="col-md-7 col-sm-7 col-7 left-text">
                                                        <span>{showCalculatorData(item.value)}</span>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                    <div className='mt-4'>
                                        <p className='bottom-text'>28% doctors took in last 5 days</p>
                                    </div>
                                    <div className='mt-5'>
                                        <button className='btn1' onClick={() => hideExpandedCard()}>Hide Details</button>
                                        <button className='pdf ml-3' onClick={() => downloadPdf()}></button>
                                        <button disabled={disableMailBtn} className='btn1 ml-3' onClick={() => sendEmail()}>Email</button>
                                        <button disabled={premium == 'NA' || !premium ? true : false} className='btn2 ml-3' onClick={() => redirectToProposal()}>Buy Now</button>
                                    </div>
                                    <div className='mt-5'>
                                        <span style={{cursor:'pointer'}} onClick={() => showStandardExcl()}><u>Standard Exclusions Applicable</u></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal isOpen={showExcl} toggle={()=>setShowExcl(false)} size="lg">
                <ModalBody>
                    <div className='row' style={{fontWeight: 700, cursor:'pointer'}}>
                        <h5 className='col-11' style={{ color: '#00628E', textAlign: 'center' }}>Standard Exclusions Applicable</h5>
                        <p style={{fontSize:'22px', fontWeight:'700'}} className='col-1' onClick={()=>closeStandardExcl()}>×</p>
                    </div>

                    <table className="table">
                        <tbody>
                            {static_data.Std_clause && static_data.Std_clause.map((item) => {
                                return <tr>
                                    <td className='p-0'>
                                        <div style={{ padding: '10px' }}>
                                            {item}
                                        </div>
                                    </td>
                                </tr> 
                            })}
                        </tbody>
                    </table>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default ExpandedCard