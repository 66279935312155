import React, { useState, useRef } from 'react'
import { Button, Input, FormGroup, Label } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import DatePicker from '../../../Inputs/Datepicker'
import MultiInvoiceDataTable from './MultiInvoiceDataTable';

const MultiInvoiceFields = ({ maxDate, setMultiInvoiceData }) => {
    const [invoiceData, setInvoiceData] = useState({ Invoice_Date: '', Invoice_Number: '', Invoice_FilePath: '' })
    const [tableInvoiceData, setTableInvoiceData] = useState([])
    const fileInputRef = useRef(null)

    const handleInputChange = (e) => {
        let tempInvoiceData = {...invoiceData}
        tempInvoiceData[e.target.name] = e.target.value
        setInvoiceData(tempInvoiceData)
    }

    const handleFiles = (e) => {
        let tempInvoiceData = {...invoiceData}
        tempInvoiceData[e.target.name] =  e.target.files[0]
        setInvoiceData(tempInvoiceData)
    }

    const addInvoiceDataRow = () => {
        const { Invoice_Date, Invoice_Number, Invoice_FilePath } = invoiceData;

        // Check if all fields have values before adding a row
        if (!Invoice_Date || !Invoice_Number || !Invoice_FilePath) {
            toast.error("Please fill out all fields before adding the invoice data.");
            return;
        }

        // Add the current invoice data to the table and reset fields
        setTableInvoiceData([...tableInvoiceData, invoiceData]);
        setInvoiceData({ Invoice_Date: '', Invoice_Number: '', Invoice_FilePath: null });

        // Clear the file input field
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
        setMultiInvoiceData([...tableInvoiceData, invoiceData])
    };

    const removeInvoiceTableData=(id)=>{
        let tempInvoiceTable = [...tableInvoiceData]
        let updatedInvoiceData = tempInvoiceTable.filter((_, i) => i !== id);
        setTableInvoiceData(updatedInvoiceData);
    }

    return (
        <>
            <ToastContainer />
            <div className='row'>
                <div className='col-12 text-right mb-2'>
                    <Button onClick={addInvoiceDataRow} color="primary">Add</Button>
                </div>
            </div>

            <table className="table">
                <tbody>
                    <tr>
                        <td>
                            <DatePicker
                                maxDate={maxDate}
                                name="Invoice_Date"
                                label={"Invoice Date"}
                                placeholder={"Invoice Date"}
                                // required={true}
                                className={"required"}
                                handleChange={handleInputChange}
                                onKeyDown={(e) => e.preventDefault()}
                                style={{ fontSize: '12px' }}
                                value={invoiceData.Invoice_Date}
                            />
                        </td>
                        <td>
                            <FormGroup>
                                <Label className={"required"} style={{ fontSize: '12px' }}>Invoice Number</Label>
                                <Input
                                    type="text"
                                    // required={true}
                                    name="Invoice_Number"
                                    onChange={handleInputChange}
                                    placeholder={"Invoice Number"}
                                    value={invoiceData.Invoice_Number}
                                    min={0}
                                />
                            </FormGroup>
                        </td>
                        <td>
                            <FormGroup>
                                <Label style={{ fontSize: '12px' }}>Invoice File (png/jpg/jpeg/pdf &lt;10MB)</Label>
                                <Input
                                    type="file"
                                    name="Invoice_FilePath"
                                    // required={true}
                                    className={"required"}
                                    innerRef={fileInputRef}
                                    autoComplete="off"
                                    onChange={handleFiles}
                                />
                            </FormGroup>
                        </td>
                    </tr>
                </tbody>
            </table>

            {tableInvoiceData && tableInvoiceData?.length > 0 ?
            <MultiInvoiceDataTable
                tableData={tableInvoiceData}
                type="edit"
                removeInvoiceTableData={(val)=>removeInvoiceTableData(val)}
            /> : null}
        </>
    );
};

export default MultiInvoiceFields
