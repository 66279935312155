import React from 'react';
// import Router from 'next/router'
import logo from "../assets/img/brand/securenow-logo.png";
// import AxiosFetchHelper from '../../Helpers/API';
import * as axios from "axios";
var myVar;

class PayByRazorPay extends React.Component {

    constructor(props) {
        super(props);
        this.txnId = ''
        this.state = {
            id: '',
            txnID: '',
            txnRef: ''
        }
    }

    openPayModal = () => {
        console.log(process.env.REACT_APP_BASE_URL);
        console.log(process.env.REACT_APP_RAZORPAYKEY);
        const self = this;
        const options = {
            //key: 'rzp_test_7oAe6WzF8NCEPe',
            key: this.keyId ,
            //amount: '100', //  = INR 1
            name: 'SecureNow',
            description: 'Purchase description',
            image: 'https://static-files-website.s3.ap-south-1.amazonaws.com/securenow_logo.svg',
            order_id: this.txnId,

            prefill: {

            },
            notes: {

            },
            theme: {
                color: "#F37254"
            },
            // callback_url: `https://ms.securenow.in/payment-success?txnId=${this.txnId}`,
            handler(response) {
                if (response.razorpay_payment_id) {
                    console.log("razzz", response)
                    if (response.razorpay_signature && response.razorpay_payment_id && !response.error) {
                        // hit backend api
                        axios({
                            method: 'post',
                            url: process.env.REACT_APP_BASE_URL + '/transaction/payment-acknowledge-razorpay',
                            params:{
                                secureNowTxnRefId:self.state.txnRef,
                                razorpaySignature:response.razorpay_signature,
                                razorpayPaymentId:response.razorpay_payment_id,
                                razorpayOrderId:response.razorpay_order_id
                            }
                        }).then((resp) => {
                            console.log("AFHRESP>>>>>", resp);
                            console.log(resp.data.data)
                            if(localStorage.getItem("partner") && localStorage.getItem("coi_path")){
                             localStorage.removeItem("partner");
                             localStorage.removeItem("coi_path");
                            }
                            if(resp && resp.data.data){
                                if(resp.data.data.partner && resp.data.data.coi_path){
                                    localStorage.setItem("partner",resp.data.data.partner)
                                    localStorage.setItem("coi_path",resp.data.data.coi_path)
                                }
                                if(resp.data.data.dpi_legal){
                                    axios({
                                        method: 'post',
                                        url: process.env.REACT_APP_WEB_PAM_URL + "/profession/api/v1/payment-acknowledge-razorpay",
                                        params:{
                                            txnId:response.razorpay_order_id,
                                            razorpaySignature:response.razorpay_signature,
                                            razorpayPaymentId:response.razorpay_payment_id,
                                            isChannelPlatform:true
                                        }
                                    }).then((res)=>{
                                        self.props.history.push({
                                            pathname: "/payment-success/dpiLegal="+true
                                        })
                                    }).catch((err)=>{
                                       console.log(err)
                                    })
                                }
                                if(resp.data.data.partner === "Trukky" && resp.data.data.callBack_url){
                                    window.location.href = resp.data.data.callBack_url + "&isSuccess=true"
                                }
                                if(resp.data.data.partner === "Agraga" && resp.data.data.callBack_url){
                                    window.location.href = resp.data.data.callBack_url
                                }
                            }
                            else{
                                self.props.history.push({
                                    pathname: '/payment-success',
                                })
                            }
                            //nextTODO
                        }).catch((err)=>{
                            var url = window.location.href;
                            var res = url?.split('/dpiLegal=')[1]
                            if(res === "true"){
                                axios({
                                    method: 'post',
                                    url: process.env.REACT_APP_WEB_PAM_URL + "/profession/api/v1/payment-acknowledge-razorpay",
                                    params:{
                                        txnId:self.state.txnRef,
                                        razorpaySignature:response.razorpay_signature,
                                        razorpayPaymentId:response.razorpay_payment_id,
                                        isChannelPlatform:true
                                    }
                                }).then((res)=>{
                                    self.props.history.push({
                                        pathname: "/payment-success/dpiLegal="+true
                                    })
                                }).catch((err)=>{
                                    console.log(err)
                                    self.props.history.push({
                                        pathname: "/payment-success/dpiLegal="+true
                                    })
                                })
                            } 
                        })
                    } else {
                        alert("PAYMENT FAILED")
                    }
                }
            },
            redirect: false
        };
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
        clearInterval(myVar);
    };

    componentDidMount() {
        this.keyId = process.env.REACT_APP_RAZORPAYKEY
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        var url = window.location.href;
        var keyArr = url.split("txnId=")[1].split("/txnRef=")
        var res = url?.split('/dpiLegal=')[1]
        var tx = keyArr[1].split("/")[0]
        this.txnId = keyArr[0]
        if(res === "true"){
            this.keyId = process.env.REACT_APP_RAZORPAYKEY_DPI
            this.setState({
                txnID: keyArr[0],
                txnRef: tx
            })
        } 
        else {
            this.setState({
                txnID: keyArr[0],
                txnRef: keyArr[1]
            })
        }

        document.body.appendChild(script);
        console.log(this.txnId);
        myVar = setInterval(() => {
            this.openPayModal();
        }, 1000);
    }

    render() {
        return (
            <div></div>
        )
    }
}

export default PayByRazorPay;
















