import React from "react";
import { Input, FormGroup, Label } from "reactstrap";

const InputCheckboxField = ({
  name,
  placeholder,
  className,
  required,
  handleChange,
 
}) => (
    <FormGroup>
    <Input
      type="checkbox"
      name={name}
      placeholder={placeholder}
      required= {(required === 'MANDATORY') ? true : false}
      onChange={handleChange}
      autoComplete="off"
    />
  </FormGroup>
);

export default InputCheckboxField;
