import React, { useState } from 'react'
import "./MedicalEstablishment.css"
import DropdownSelect from '../../../Inputs/SelectField'
import InputTextField from '../../../Inputs/InputField'
import { Button } from 'reactstrap'
import axios from 'axios'
const MedicalEstablishment = ({ insurerName, inputData, updateStep, mainHandleChange }) => {
    const [medicalEstablishmentStep, setMedicalEstablishmentStep] = useState(1);
    const [formDetails, setFormDetails] = useState({})
    const [errMessage, setErrMessage] = useState('')


    const handleChange = (e) => {
        const temp = { ...formDetails }
        temp[e.target.name] = e.target.value
        console.log("temprary baby: ", temp);
        setFormDetails(temp)
        setErrMessage('')
        mainHandleChange(e);
    }

    const validateBeforeSubmit = () => {
        console.log("formdetails :", formDetails)
        // formDetails.Product Name
        let err = ""
        // if (!formDetails.Product) {
        //     err = "Please select Product"
        // }
        if (!formDetails.Lead_Type) {
            err = "Please select lead type"
        }
        else if (!formDetails.Rate) {
            err = "Please enter rate"
        }
        return err
    }


    const handleSaveAndNext = (e) => {
        e.preventDefault()
        let isValid = validateBeforeSubmit()
        if (isValid) {
            setErrMessage(isValid)
        }
        else {
            updateStep(2)
        }
    }

    return (
        <div className='medicalEstablishment-form'>
            <div className='container'>
                <div className='text-center'>
                    <img src={'https://static-files-website.s3.ap-south-1.amazonaws.com/securenow_logo.svg'} alt="" style={{ padding: "20px 0" }} />
                </div>
                <h4 className='text-center'>Proposal Form for <br /> {insurerName}</h4>
                <div>
                    {inputData.length > 0 && inputData.map((form, i) => {
                        if ((form?.inputType === "VARCHAR-DROPDOWN") && form?.display === "YES") {
                            return (
                                <DropdownSelect
                                    key={i}
                                    name={form.name}
                                    label={form.description}
                                    placeholder={form.description}
                                    required={form.mandatory === "MANDATORY"}
                                    handleChange={handleChange}
                                    val={form?.inputValue}
                                    selectname={form.description}
                                    className={form.mandatory === "MANDATORY" ? "required" : ""}
                                    isFlag={"dynamic"}
                                />
                            )
                        }
                        else if (form?.inputType === "VARCHAR" && form?.display === "YES") {
                            return (
                                <InputTextField
                                    type={"text"}
                                    key={i}
                                    name={form.name}
                                    label={form.description}
                                    placeholder={form.description === 'GST' ? 'Eg. 123456789ABCDEA' : form.description}
                                    required={form.mandatory}
                                    disable={form.inputBy}
                                    prefilled={form?.inputValue}
                                    handleChange={handleChange}
                                    className={
                                        form.mandatory === "MANDATORY" ? "required" : ""
                                    }
                                    maxLength={form?.name == "Pan_Number" ? 10 : null}
                                />
                            );
                        }
                    })}
                </div>
                <p className='text-danger text-center mb-2'>{errMessage}</p>
                <Button
                    color="primary"
                    type="submit"
                    className="mb-4 mt-4"
                    onClick={handleSaveAndNext}
                    style={{ backgroundColor: '#f15a23', width: '100%', outline: 'none', border: 'none' }}
                >Save & Next</Button>
            </div>
        </div>
    )
}

export default MedicalEstablishment