import React from "react";
import { Input } from "reactstrap";

const DropdownSelect = ({
  name,
  label,
  placeholder,
  required,
  handleChange,
  val,
  selectname,
  className,
  isFlag,
  inputId,
  value,
  disabled, url
}) => (
  <div className="form-group">
    <label className={className}>{label}</label>
    {isFlag === "dynamic" ? (
      <Input
        disabled={disabled}
        type="select"
        required={required === "MANDATORY"}
        name={name}
        bsSize="sm"
        onChange={handleChange}
        value={value} // Do not force "Inland" as value
      >
        {/* Show "Inland" as the first option only when url matches */}
        {url === "Vaahak-Bajaj-Specific-Marine" && selectname === "Type of Single Transit Policy" && (
          <option value="">Inland</option> // Acts as a placeholder but does not send "Inland" to API unless selected
        )}

        <option value="">Select {selectname}</option>
        {val.split("||").map((item, i) => (
          <option key={i} value={item}>
            {item}
          </option>
        ))}
      </Input>

    ) : (
      <Input
        disabled={disabled}
        id={inputId}
        type="select"
        required={required}
        name={name}
        bsSize="md"
        onChange={handleChange}
        value={value}
      >
        <option selected="selected" disabled="disabled" value="">Select {selectname}</option>
        {/* <option>Please Country</option> */}
        {val.map(({ id, name }, index) => (
          <option key={index} value={id} disabled={id === '' ? true : false}>
            {name}
          </option>
        ))}
      </Input>
    )}
  </div>
);
export default DropdownSelect;
