import React from "react";
import { Input, FormGroup, Label } from "reactstrap";

const DatePicker = ({
  name,
  label,
  placeholder,
  required,
  handleChange,
  className,
  maxDate,
  value,
  disabled,
  minDate,
  onkeyDown,
  Style
}) => (
  <FormGroup>
    <Label style={Style || null} className={className}>{label}</Label>
    <Input
      type="date"
      max={maxDate}
      min={minDate}
      name={name}
      onChange={handleChange}
      placeholder={placeholder}
      required= {(required === 'MANDATORY') ? true : false}
      value={value}
      disabled={disabled}
      onKeyDown={onkeyDown}
    />
  </FormGroup>
);

export default DatePicker;
