// import React, { Component } from "react";
// import { HashRouter, Route, Switch } from "react-router-dom";
// import { loadReCaptcha } from "react-recaptcha-v3";
// // import { renderRoutes } from 'react-router-config';
// import "./App.scss";
// import * as axios from "axios";
// import ForgotPassword from "./views/Pages/Password/ForgotPassword";
// import ResetPassword from "./views/Pages/Password/ResetPassword";
// import ProposalForm from "./views/Pages/ProposalForm";
// import PaymentStatus from "./views/Pages/payment-success";
// import PaymentStatusDpi from "./views/Pages/payment-success";
// import PayByRazorPay from './razorpay/razorpay';
// import PayByRazorPayDpi from './razorpay/razorpay'

// const loading = () => (
//   <div className="animated fadeIn pt-3 text-center">Loading...</div>
// );

// // Containers
// const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// // Pages
// const Login = React.lazy(() => import("./views/Pages/Login"));
// // const Register = React.lazy(() => import("./views/Pages/Register"));
// // const Page404 = React.lazy(() => import("./views/Pages/Page404"));
// // const Page500 = React.lazy(() => import("./views/Pages/Page500"));

// class App extends Component {
//   componentDidMount() {
//    // loadReCaptcha("6Lf6LdAUAAAAAILDdXgO693qGB9VeXo2AIN_Rps5");
//     this.myfunc();
//   }

//   myfunc = async () => {
//     axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
//     const AUTH_TOKEN = await localStorage.getItem("auth-Token");
//     console.log("in index", AUTH_TOKEN);
//     axios.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
//     axios.defaults.headers.post["Content-Type"] =
//       "application/x-www-form-urlencoded";
//   };
//   render() {
//     return (
//       <HashRouter>
//         <React.Suspense fallback={loading()}>
//           <Switch>
//             <Route
//               exact
//               path="/"
//               name="Login Page"
//               render={props => <Login {...props} />}
//             />
//              <Route
//               exact
//               path="/forgot-password"
//               name="Forgot Password"
//               render={props => <ForgotPassword {...props} />}
//             />
//              <Route
//               exact
//               path="/reset-password"
//               name="Reset Password"
//               render={props => <ResetPassword {...props} />}
//             />
//              <Route
//               path="/web"
//               name="Proposal Form"
//               render={props => <ProposalForm {...props} />}
//             />

//             <Route
//               exact
//               path="/razorPay/txnId=:param1/txnRef=:param2"
//               name="RazorPay"
//               render={props => <PayByRazorPay {...props} />}
//             />

//             <Route
//               exact
//               path="/razorPay/txnId=:param1/txnRef=:param2/dpiLegal=:param3"
//               name="RazorPay"
//               render={props => <PayByRazorPayDpi {...props} />}
//             />

//           <Route
//               exact
//               path="/payment-success"
//               name="Payment Status"
//               render={props => <PaymentStatus />}
//             />
            
//             <Route
//               path="/payment-success/dpiLegal=:param1"
//               name="Payment Status DPI"
//               render={props => <PaymentStatusDpi {...props}/>}
//             />



//             {/* <Route
//               exact
//               path="/register"
//               name="Register Page"
//               render={props => <Register {...props} />}
//             />
//             <Route
//               exact
//               path="/404"
//               name="Page 404"
//               render={props => <Page404 {...props} />}
//             />
//             <Route
//               exact
//               path="/500"
//               name="Page 500"
//               render={props => <Page500 {...props} />}
//             /> */}
//             <Route
//               path="/"
//               name="Home"
//               render={props => <DefaultLayout {...props} />}
//             />
//             {/* <Route path="/razorPay/txnId=:param1/txnRef=:param2" render={(props) => <PayByRazorPay {...props} />} /> */}
//           </Switch>
//         </React.Suspense>
//       </HashRouter>
//     );
//   }
// }

// export default App;


// ---------------------New Code-------------------------

import React, { Component, useEffect } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { loadReCaptcha } from "react-recaptcha-v3";
import "./App.scss";
import * as axios from "axios";
import ForgotPassword from "./views/Pages/Password/ForgotPassword";
import ResetPassword from "./views/Pages/Password/ResetPassword";
import ProposalForm from "./views/Pages/ProposalForm";
import PaymentStatus from "./views/Pages/payment-success";
import PaymentStatusDpi from "./views/Pages/payment-success";
import PayByRazorPay from "./razorpay/razorpay";
import PayByRazorPayDpi from "./razorpay/razorpay";
import { getUserDetails } from "./Shared/ApiConstants";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));

/**
 * ProtectedRoute Component
 * Checks if the user is authenticated based on auth-Token in localStorage.
 * Redirects to login page if not authenticated.
 */
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem("auth-Token");
  const path = window.location.href
  console.log(path);
  useEffect(() => {
    localStorage.setItem("UrlPath",path)
    if(localStorage.getItem("auth-Token")){
      getUserCred()
    }
   
  }, [path])

  
  const getUserCred=()=> {
    var config = {
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
        Authorization: "Bearer " + localStorage.getItem("auth-Token")
      }
    };
    axios
    .get(getUserDetails, config)
      .then(res => {
      })
      .catch(err => {
        if (err.response && err.response.status === 401 && err.response.data.error === "Unauthorized") {
          localStorage.clear()
          window.location.href = "/";
        } else {
          console.error("Error fetching total premium:", err);
        }
      });
  }
  
  
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

class App extends Component {
  componentDidMount() {
    this.setupAxiosDefaults();
  }

  setupAxiosDefaults = async () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    const AUTH_TOKEN = await localStorage.getItem("auth-Token");
    console.log("in index", AUTH_TOKEN);
    axios.defaults.headers.common["Authorization"] = `Bearer ${AUTH_TOKEN}`;
    axios.defaults.headers.post["Content-Type"] =
      "application/x-www-form-urlencoded";
  };

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route
              exact
              path="/"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/forgot-password"
              name="Forgot Password"
              render={(props) => <ForgotPassword {...props} />}
            />
            <Route
              exact
              path="/reset-password"
              name="Reset Password"
              render={(props) => <ResetPassword {...props} />}
            />
            <ProtectedRoute
              path="/web"
              name="Proposal Form"
              component={ProposalForm}
            />
            <Route
              exact
              path="/razorPay/txnId=:param1/txnRef=:param2"
              name="RazorPay"
              render={(props) => <PayByRazorPay {...props} />}
              // component={PayByRazorPay}
            />
            <Route
              exact
              path="/razorPay/txnId=:param1/txnRef=:param2/dpiLegal=:param3"
              name="RazorPay DPI"
              render={(props) => <PayByRazorPayDpi {...props} />}
              // component={PayByRazorPayDpi}
            />
            <Route
              exact
              path="/payment-success"
              name="Payment Status"
               render={(props) => <PaymentStatus {...props} />}
              // component={PaymentStatus}
            />
            <Route
            exact
              path="/payment-success/dpiLegal=:param1"
              name="Payment Status DPI"
              render={(props) => <PaymentStatusDpi {...props} />}
              // component={PaymentStatusDpi}
            />
            {/* <ProtectedRoute
            exact
              path="/payment-success/dpiLegal=:param1"
              name="Payment Status DPI"
           
              component={PaymentStatusDpi}
            /> */}
            <ProtectedRoute path="/" name="Home" component={DefaultLayout} />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
