
  import React from "react"


  
  const stateList=[
    {"STATE_ID":"27","STATE":"MAHARASHTRA","IL State":"55","IL State Name":"MAHARASHTRA","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"8","STATE":"RAJASTHAN","IL State":"56","IL State Name":"RAJASTHAN","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"33","STATE":"TAMIL NADU","IL State":"58","IL State Name":"TAMIL NADU","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"29","STATE":"KARNATAKA","IL State":"59","IL State Name":"KARNATAKA","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"32","STATE":"KERALA","IL State":"62","IL State Name":"KERALA","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"23","STATE":"MADHYA PRADESH","IL State":"64","IL State Name":"MADHYA PRADESH","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"9","STATE":"UTTAR PRADESH","IL State":"65","IL State Name":"UTTAR PRADESH","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"24","STATE":"GUJARAT","IL State":"67","IL State Name":"GUJARAT","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"3","STATE":"PUNJAB","IL State":"69","IL State Name":"PUNJAB","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"19","STATE":"WEST BENGAL","IL State":"72","IL State Name":"WEST BENGAL","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"7","STATE":"DELHI","IL State":"74","IL State Name":"DELHI","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"37","STATE":"ANDHRA PRADESH","IL State":"124","IL State Name":"ANDHRA PRADESH","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"6","STATE":"HARYANA","IL State":"127","IL State Name":"HARYANA","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"21","STATE":"ORISSA","IL State":"132","IL State Name":"ORISSA","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"20","STATE":"JHARKHAND","IL State":"138","IL State Name":"JHARKHAND","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"22","STATE":"CHHATTISGARH","IL State":"144","IL State Name":"CHHATTISGARH","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"30","STATE":"GOA","IL State":"145","IL State Name":"GOA","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"12","STATE":"ARUNACHAL PRADESH","IL State":"146","IL State Name":"ARUNACHAL PRADESH","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"10","STATE":"BIHAR","IL State":"147","IL State Name":"BIHAR","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"2","STATE":"HIMACHAL PRADESH","IL State":"148","IL State Name":"HIMACHAL PRADESH","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"1","STATE":"JAMMU & KASHMIR","IL State":"149","IL State Name":"JAMMU & KASHMIR","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"14","STATE":"MANIPUR","IL State":"150","IL State Name":"MANIPUR","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"17","STATE":"MEGHALAYA","IL State":"151","IL State Name":"MEGHALAYA","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"15","STATE":"MIZORAM","IL State":"152","IL State Name":"MIZORAM","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"13","STATE":"NAGALAND","IL State":"153","IL State Name":"NAGALAND","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"11","STATE":"SIKKIM","IL State":"154","IL State Name":"SIKKIM","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"16","STATE":"TRIPURA","IL State":"155","IL State Name":"TRIPURA","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"5","STATE":"UTTARAKHAND","IL State":"156","IL State Name":"UTTARANCHAL","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"4","STATE":"CHANDIGARH","IL State":"160","IL State Name":"CHANDIGARH","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"25","STATE":"DAMAN & DIU","IL State":"161","IL State Name":"DAMAN & DIU","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"26","STATE":"DADRA & NAGAR HAVELI","IL State":"162","IL State Name":"DADRA & NAGAR HAVELI","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"31","STATE":"LAKSHADWEEP","IL State":"163","IL State Name":"LAKSHADWEEP","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"34","STATE":"PONDICHERRY","IL State":"164","IL State Name":"PONDICHERRY","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"35","STATE":"ANDAMAN & NICOBAR ISLANDS","IL State":"165","IL State Name":"ANDAMAN & NICOBAR ISLANDS","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"18","STATE":"ASSAM","IL State":"54","IL State Name":"ASSAM","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"7","STATE":"DELHI","IL State":"192","IL State Name":"NEW DELHI","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"5","STATE":"UTTARAKHAND","IL State":"342","IL State Name":"UTTARAKHAND","IL Country Code":"100","IL Country Name":"INDIA"},
    {"STATE_ID":"36","STATE":"TELANGANA","IL State":"434","IL State Name":"TELANGANA","IL Country Code":"100","IL Country Name":"INDIA"}
    ]

    const static_commodity_data_agraga = [
      {id: "COM01", name: "New Machinery or mechanical/ electrical/ electronic equipment for industrial use"},
      {id: "COM02", name: "Secondhand/ Used Machinery or mechanical/ electrical/ electronic equipment for industrial use"},
      {id: "COM04", name: "Coal (Coke/ Bituminous coal/ Sub-bituminous coal/ Coking coal/ Smithing coal/ Peat/ Lignite/ Steam Coal/ Anthracite/ Metallurgical coke/ Charcoal)"},
      {id: "COM05", name: "Metallic Ores and Ore Concentrates (Iron/ Bog/ Manganese/ Nickel/ Copper/ Lead/ Bauxite (Aluminium)/ Zinc/ Chrome/ Sponge Iron/ Direct Reduced Iron (DRI)/ Silicon Mangenese/ Ilmenite)"},
      {id: "COM06", name: "Minerals (Mercuric Sulphide/ Dolomite/ Bentonite/ Bleaching Earth/ Kaolin/ Attapulgite/ Soda Ash/ Aluminium Silicate/ Potassium sulphate/ Limestone/ Clay/ Sand/ Gravel/ Diatomite/ Silica/ Barite/ Gypsum/ Talc/ Natural graphite)"},
      {id: "COM07", name: "Pharmaceuticals (Tablet/Syrup/Injection), APIs and Bulk Drugs"},
      {id: "COM08", name: "Agri Commodities (Wheat/ Grains/ Seeds/ Rice/ Spices/ Pulses)"},
      {id: "COM09", name: "Used Household Goods for Shifting of Residence"},
      {id: "COM10", name: "All types of Non-Refrigerated Container"},
      {id: "COM11", name: "Refrigerated Container"},
      {id: "COM12", name: "New Machinery spare parts/ Automobile parts/ Hardware tools"},
      {id: "COM13", name: "Readymade Garments/Clothes/Footwear"},
      {id: "COM14", name: "Natural or synthetic yarn/Fabrics/Thread (Excluding cotton in raw or fully pressed bale form)"},
      {id: "COM15", name: "Leather/Animal hides and skins including fur"},
      {id: "COM16", name: "Articles of leather/Saddlery and harness/travel bags, baggage, handbags and similar"},
      {id: "COM17", name: "Stationery products/Office supplies/Ink cartridges"},
      {id: "COM19", name: "Liquid chemicals/Paints/Dyes/Intermediates"},
      {id: "COM20", name: "Dry chemicals/Fertilizers/Pesticides/Dyes/Intermediates"},
      {id: "COM21", name: "New Metal rods/Sheets/Coils/Pipes/Cables/Wires/Billets/Ignots/Cathodes (Excluding precious metals and metal scrap)"},
      {id: "COM22", name: "New Articles of plastic/plastic made items"},
      {id: "COM23", name: "Metal shavings and scrap (Excluding precious metals)"},
      {id: "COM24", name: "ISO Tanks"},
      {id: "COM25", name: "Processed food/edible items (Confectionery like chocolates and candies/ baked goods/ biscuits and other snacks/ canned goods/ pickles and jams/ ready to eat meals/ pastas/ noodles etc)"},
      {id: "COM26", name: "Semi-finished fragile goods like slabs, blocks or sheets for industrial/commercial use (made of marble, granite or other stones/glass /ceramic)"},
      {id: "COM27", name: "Finished fragile goods made of ceramic/ glass/ marble/ granite/ stone (Like tiles, sanitaryware, lighting equipment, handicrafts etc)"},
      {id: "COM28", name: "Fast moving consumer goods (FMCG) in retail packing (soaps/ detergents/ oral care products/ personal care products/ hair care products/ home care products/ cosmetics/ perfumes etc)"},
      {id: "COM29", name: "Raw plastic/ plastic granules"},
      {id: "COM30", name: "Natural or raw rubber in sheets, blocks, crepe or crumb form"},
      {id: "COM31", name: "Articles made of rubber (tyres, tubes etc)"},
      {id: "COM33", name: "New furniture, modular kitchen sets and the like made of metal/ wood/ glass"},
      {id: "COM34", name: "Plants, flowers and other horticulture products"},
      {id: "COM35", name: "New electronic goods and their spares (Computers and their peripherals/ tablets/ mobile phones/ cables/ chargers etc)"},
      {id: "COM36", name: "Secondhand/ Used electronic goods and their spares (Computers and their peripherals/ tablets/ mobile phones/ cables/ chargers etc)"},
      {id: "COM43", name: "Personal hair accessories and artificial jewellery (Excluding watches, sunglasses and, any precious and semi-precious metals or stones)"},
      {id: "COM44", name: "Fabric or synthetic flooring tiles and artificial grass including rugs, carpets and floormats"},
      {id: "COM45", name: "Solar Panels and Modules"},
      {id: "COM50", name: "Perishable foods like fruits and vegetables"},
      {id: "COM53", name: "Edible vegetable or animal fats and oils"},
      {id: "COM54", name: "Toys and other children's games with or without electrical or mechanical parts"},
      {id: "COM57", name: "Dairy products like Milk, ghee, cheese, butter etc"},
      {id: "COM58", name: "Frozen meat of all kinds"},
      {id: "COM64", name: "Jute Rope/ Jute Hessian Cloth/ Jute Bag/ Jute Tape/ Jute Felt/ Cotton bags"},
      {id: "COM65", name: "Sports Associries/ Equipment"},
      {id: "COM67", name: "Cement (Basic Risk)"},
      {id: "COM68", name: "Gold, silver, platinum and other precious metals in any form and/or jewellery (Excluding any precious and semi-precious stones) (Basic Risk)"},
      {id: "COM70", name: "New - IT equipments and servers"},
      {id: "COM71", name: "White Goods/ Large Appliance - AC, Fridge, LED, washing machine, Microwave, Dishwasher"},
      {id: "COM72", name: "secondhand/used white goods - White Goods/ Large Appliance - AC, Fridge, LED, washing machine, Microwave, Dishwasher"},
      {id: "COM73", name: "New - CPM equipment (excavator, road roller) and tractors"},
      {id: "COM74", name: "Second hand/used - CPM equipment (excavator, road roller) and tractors"},
      {id: "COM77", name: "Spectales/sunglasses/watches/perfumes/ Gromming accessories"}
    ];

    const incoTermsData = [
      {id: "CIP", name: "Carriage And Insurance Paid To(CIP)"},
      {id: "CPT", name: "Carriage Paid To(CPT)"},
      {id: "CFR", name: "Cost And Freight(CFR)"},
      {id: "CIF", name: "Cost, Insurance And Freight(CIF)"},
      {id: "DPU", name: "Delivered At Place Unloaded(DPU)"},
      {id: "DAP", name: "Delivered At Place(DAP)"},
      {id: "DDP", name: "Delivered Duty Paid(DDP)"},
      {id: "EXW", name: "Ex Works(EXW)"},
      {id: "FAS", name: "Free Alongside Ship(FAS)"},
      {id: "FCA", name: "Free Carrier(FCA)"},
      {id: "FOB", name: "Free On Board(FOB)"}
  ]
  
    const staticLocalData = {
        "stateList": stateList,
        "agraga_commodity_data": static_commodity_data_agraga,
        "Incoterms_data": incoTermsData
    }

    export default staticLocalData