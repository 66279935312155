import React, { Component } from "react";
import {Button,Card,CardBody,Col, Container,Form,Input,InputGroup, Row} from "reactstrap";
import axios from "axios";
import { forgotPassword } from "../../../../Shared/ApiConstants";

class ForgotPassword extends Component {
  state={
    email:"",
    errorMsg:"",
    color:"red"
  }
  handleChange = (e) =>{
     this.setState({
       [e.target.name]:e.target.value
     })
  }

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  sendMail = (e) =>{
    e.preventDefault()
    if(!this.validateEmail(this.state.email)){
      this.setState({
        errorMsg:"Please enter your email address"
      })
    }else{
      axios.post(forgotPassword(this.state.email)).then(res => {
        if(res.status==200){
          this.setState({
            color:"#196F3D",
            errorMsg:"Reset Password link has been sent to your EmailId"
          })
        }
      }).catch(err=>{
        console.log("err is",err)
          this.setState({
              errorMsg:`We couldn't find an account associated with ${this.state.email}.`
          })
      });
    }
  }
  render() {
    return (
      <div className="flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col >               
                <Card  style={{border:"0px", textAlign: "center"}}>
                  <CardBody className="p-5">
                    <Form className="form" onSubmit={this.sendMail}>
                    <h2><b className="text-center" style={{color:"#20a8d8"}}>Forgot Password</b></h2>
                    <br />
                      <h6>Please enter your email</h6>
                      <InputGroup>
                        <Input
                          name="email"
                          placeholder="Your e-mail address"
                          value={this.state.email}
                          onChange={this.handleChange}
                        />      
                      </InputGroup>
                      <div>
                        <span  style={{ color: this.state.color, alignItems: 'center' }}>{this.state.errorMsg}</span>
                        </div> 
                       <br/>
                      <Row>
                        <Col>
                          <Button
                            type="submit"
                            color="primary"
                            className="px-4"
                          >
                            Reset my Password
                            </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
            </Col>
          </Row>
        </Container>

      </div>
    )
  }
}

export default ForgotPassword
