import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const PolicyPremium = props => {
  const {
    buttonLabel,
    className,
    bodyText,
    title,
    disable,
    onClickFunc,
    isFLag,
    callOnOpen,
    formData,
    cal
  } = props;

  const [modal, setModal] = useState(false);
  const [backdrop] = useState(true);
  const [keyboard] = useState(true);
  // const [disable] = useState(true);
  // eslint-disable-next-line no-unused-expressions
  const toggle = () => {
    setModal(!modal);
    if (formData === true) {
      callOnOpen();
    }
  };

  return (
    <div>
      <Button color="primary" onClick={toggle} disabled={disable}>
        {buttonLabel}
      </Button>

      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        backdrop={backdrop}
        keyboard={keyboard}
      >
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>{bodyText}</ModalBody>
        <ModalFooter>
          {cal === "YES" ? (
            <Button color="secondary" onClick={toggle}>
              Recalculate
            </Button>
          ) : null}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PolicyPremium;
