const serverUrl = process.env.REACT_APP_BASE_URL;
// const loginUrl = process.env.REACT_APP_BASE_URL_LOGIN;
// console.log(loginUrl);

// export const LOGIN = `${loginUrl}/auth/login`;

export const LOGIN = `${serverUrl}/authenticate`;
export const checkuser = `${serverUrl}/checkUser`;

export const GET_LEDGER = (page, size) =>
  `${serverUrl}/ledger?page=${page}&size=${size}`;

export const ADD_LEDGER = `${serverUrl}/ledger`;

export const Download_transactions = (accountId, startDate, endDate) =>
 `${serverUrl}/lead/download/account-balance/${accountId}?startDate=${startDate}&endDate=${endDate}`;

//Dwonload Excel lead
export const Download_lead = (startDate, endDate) =>
 `${serverUrl}/lead/download/sanctum/?startDate=${startDate}&endDate=${endDate}`;

 //Check if Santrum

 export const Check_ifSantrum = (Id) =>
 `${serverUrl}/leads/users/${Id}`;


/**Products */
export const GET_PRODUCTS = (createdDate,page, size, search, product, category) =>
  `${serverUrl}/master/product?sort=${createdDate},DESC&page=${page}&size=${size}&search=${search}&product=${product}&category=${category}`;

export const GET_ALL_PRODUCTS = () =>
  `${serverUrl}/master/products`;
    

export const GET_PROD_LIST = `${serverUrl}/master/products`;

export const ADD_PRODUCTS = `${serverUrl}/master/product`;

export const GET_PRODUCT_BY_ID = id => `${serverUrl}/master/product/${id}`;

export const EDIT_PRODUCT = `${serverUrl}/master/product`;

export const DELETE_PROD = id => `${serverUrl}/master/product/${id}`;

export const GET_PROD_BY_ORG_ID = organizationId =>
  `${serverUrl}/master/products/${organizationId}`;

  export const SEND_COI_MAIL = id => `${serverUrl}/send-mail/transaction/${id}`;
  export const DOWNLOAD_COI_MAIL = id => `${serverUrl}/download-mail-attachment/transaction/${id}`;
  export const SendBulkMail = `${serverUrl}/bulk/send-mail/transaction`;
  export const SendBulkMailToAll = `${serverUrl}/all/send-mail/`;

/**Category */
export const GET_CATEGORY = (createdDate, page, size, search) =>
  `${serverUrl}/master/category?sort=${createdDate},DESC&page=${page}&size=${size}&search=${search}`;

export const GET_ALL_CATEGORY = () =>
  `${serverUrl}/master/category`;

export const GET_CATEGORY_PRODUCT = `${serverUrl}/master/category`;

export const ADD_CATEGORY = `${serverUrl}/master/category`;

export const GET_CATEGORY_BY_ID = id => `${serverUrl}/master/category/${id}`;

export const UPDATE_CATEGORY = `${serverUrl}/master/category`;

export const DELETE_CATEGORY = id => `${serverUrl}/master/category/${id}`;

/**Partners */
export const GET_PARTNERS = (page, size, name, pan, gstNo, searchStr) =>
  `${serverUrl}/organizations/PARTNER?page=${page?page:''}&size=${size?size:''}&name=${name?name:''}&pan=${pan?pan:''}&gstNo=${gstNo?gstNo:''}&search=${searchStr?searchStr:''}`;

export const GET_ALL_PARTNERS = () =>
  `${serverUrl}/organizations/PARTNER`; 

export const GET_INSURERS = (page, size, name, pan, gstNo, searchStr) =>
  `${serverUrl}/organizations/INSURER?page=${page?page:''}&size=${size?size:''}&name=${name?name:''}&pan=${pan?pan:''}&gstNo=${gstNo?gstNo:''}&search=${searchStr?searchStr:''}`;
 
export const GET_ALL_INSURERS = () =>
  `${serverUrl}/organizations/INSURER`;   

export const GET_PARTNER_LIST = `${serverUrl}/organizations/type/PARTNER`;
export const GET_INSURERS_LIST = `${serverUrl}/organizations/type/INSURER`;

export const ADD_PARTNER = `${serverUrl}/organization`;

export const GET_PARTNER_BY_ID = id => `${serverUrl}/organization/${id}`;

export const UPDATE_PARTNER = `${serverUrl}/organization`;
export const DELETE_PARTNER = id => `${serverUrl}/organization/${id}`;

/**Calculator */

export const GET_CALCULATOR = (createdDate, page, size, search, calcName, insurer, product) =>
  `${serverUrl}/calculator?sort=${createdDate},DESC&page=${page}&size=${size}&search=${search}&calcName=${calcName}&insurer=${insurer}&product=${product}`;
export const GET_ALL_CALCULATOR = () =>
  `${serverUrl}/calculator?page`;
export const ADD_CAL = `${serverUrl}/calculator`;
export const GET_CAL_BY_ID = id => `${serverUrl}/calculator/${id}`;
export const UPDATE_CAL = `${serverUrl}/calculator`;
export const DEL_CAL = id => `${serverUrl}/calculator/${id}`;
export const getOrgLinkCalById = organisationId =>
  `${serverUrl}/calculate/org-link/${organisationId}`;
export const DOWNLOAD_EXCEL_FILE = (calcId) =>
  `${serverUrl}/downloadExcel/${calcId}`;

/**Url */
export const GET_URL = (createdDate, page, size, search, urlName, Partner) =>
  `${serverUrl}/org-link?sort=${createdDate},DESC&page=${page}&size=${size}&search=${search}&urlName=${urlName}&partner=${Partner}`;
export const GET_ALL_URL = () =>
  `${serverUrl}/org-link?page`;  

export const ADD_URL = `${serverUrl}/url`;
export const GET_URL_BY_ID = id => `${serverUrl}/org-link/${id}`;
export const UPDATE_URL = `${serverUrl}/url`;
export const DELETE_URL = id => `${serverUrl}/org-link/${id}`;

export const GET_LINK_BY_ORG_ID = id =>
  `${serverUrl}/org-link/organisation/${id}`;

export const getModuleByOrgLinkId = organisationId =>
  `${serverUrl}/module/master/org-link/${organisationId}`; 

export const downloadOrgLinkById = organisationId =>
  `${serverUrl}/org-link/download/${organisationId}`; 

/**Users */
export const GET_USERS = (page, size) =>
  `${serverUrl}/users?page=${page}&size=${size}`;
export const ADD_USERS = `${serverUrl}/users`;
export const GET_BY_ID = id => `${serverUrl}/users/${id}`;
export const UPDATE_USER = `${serverUrl}/users`;
export const DEL_USER = id => `${serverUrl}/users/${id}`;

/**Roles */

export const GET_ROLES = `${serverUrl}/authority`;
export const VERIFY_PAN = pan => `${serverUrl}/organization/pan/${pan}`;
export const GET_COUNTRY_LIST = `${serverUrl}/country`;
export const GET_STATE_LIST = id => `${serverUrl}/state/country/${id}`;
export const GET_CITY_LIST = id => `${serverUrl}/city/state/${id}`;

/**Address */
export const ADD_ADDRESS = `${serverUrl}/address`;
export const UPDATE_ADDRESS = id => `${serverUrl}/address/${id}`;
export const GET_ADD_BY_ORG = organizationId =>
  `${serverUrl}/address/organization/${organizationId}`;
export const GET_ADDRESS = id => `${serverUrl}/address/${id}`;
export const EDIT_ADDRESS = `${serverUrl}/address`;
export const GET_USERS_BY_ORG = (organizationId, page, size) =>
  `${serverUrl}/users/organization/${organizationId}?page=${page}&size=${size}`;

export const DELETE_ADD = id => `${serverUrl}/address/${id}`;

export const GET_CALC_BY_ORG_ID = id =>
  `${serverUrl}/calculator/organisation/${id}`;

/**GET URL WITHOUT PAGINATION */
export const GET_URlS = `${serverUrl}/org-link/organisations`;
export const GET_CALS = `${serverUrl}/calculator`;
export const GET_TRAN = (page, size) =>
  `${serverUrl}/trans?page=${page}&size=${size}`;
export const GET_MASTER_LINK = `${serverUrl}/master/link`;
export const SAVE_URL = `${serverUrl}/org-link`;
export const URL_MODULE_MASTER = `${serverUrl}/module/master`;
export const GET_CAL = `${serverUrl}/calculators`;
export const URL_MAP = (orgType)=>`${serverUrl}/orglink-calculator?organizationType=${orgType}`;

// export const CAL_PREMIUM = (url, secretKey) =>
//   `${serverUrl}/secure-now/${url}/${secretKey}`;
export const CAL_PREMIUM = `${serverUrl}/calculate/premium`;
export const CAL_BY_ORG_LINK = id => `${serverUrl}/calculate/org-link/${id}`;

export const ADD_BALANCE = `${serverUrl}/secure-now/account-balance`;
export const GET_CALC_ID = (organizationId, calculatorId) =>
  `${serverUrl}/orglink-calculator/${organizationId}/${calculatorId}`;
export const GET_ALL_PRODUCTS_MASTER = `${serverUrl}/master/categories`;
export const EVALUATE_CAL = id =>`${serverUrl}/calculator/dynamic/${id}`;

/**Transaction */
export const getAllTransaction = (page, size) =>
  `${serverUrl}/transactions?page=${page}&size=${size}`;
export const getAllTransForOrgId = organizationId =>
  `${serverUrl}/transactions/${organizationId}`;
export const getAllTransForCredit = ledgerId => `${serverUrl}/ledgers/credit/${ledgerId}`

/**Ledgers */
export const getAllLedgers = `${serverUrl}/ledgers`;
export const getLedgersByAccBalId =(accBalId, page, size, filter) => filter?`${serverUrl}/ledgers/${accBalId}/${filter}?sort=createdDate,DESC&page=${page}&size=${size}` : `${serverUrl}/ledgers/${accBalId}?sort=createdDate,DESC&page=${page}&size=${size}`;
export const getAllledForOrgId = organizationId =>
  `${serverUrl}/ledgers/organization/${organizationId}`;

/**Leads */
export const getAllLeads = (createdDate, page, size, searchStr, orgName, urlName, name, email, phone) =>
  `${serverUrl}/leads?sort=${createdDate},DESC&page=${page}&size=${size}&search=${searchStr}&orgName=${orgName}&urlName=${urlName}&name=${name}&email=${email}&phone=${phone}`;
export const getAllLeadsByOrg = organizationId =>
  `${serverUrl}/leads/organization/${organizationId}`;

/** Uploads and Reports **/
export const getPartners = `${serverUrl}/organizations/type/PARTNER`;
export const getAllReports = `${serverUrl}/secure-now/partner-transactions-report?sort=createdDate,DESC`;
export const getOrgLinks=(ID) => `${serverUrl}/org-link-names/${ID}`
export const uploadExcelFile = `${serverUrl}/secure-now/lead/excel-lead-import`


/**Acount  balance */
export const getAccBalance = (date,page,size,orgName,mph,urlName,calcName,searchStr) =>
  `${serverUrl}/account/balance?sort=${date},DESC&page=${page}&size=${size}&orgName=${orgName}&mph=${mph}&urlName=${urlName}&calcName=${calcName}&search=${searchStr}`;
export const getBalByOrg = organizationId =>
  `${serverUrl}/account/balance/organization/${organizationId}`;

export const savePolicy = id => `${serverUrl}/calculator/dynamic/${id}`;
export const getLeadCount = `${serverUrl}/getLeadCount`;
export const getTotalPremium = `${serverUrl}/getTotalPremium`;
export const calcLead = (url, secretKey) =>
  `${serverUrl}/secure-now/${url}/${secretKey}`;
export const getUserDetails = `${serverUrl}/user`;

 /**** View Profile *****/
 export const getProfileDetails = `${serverUrl}/user`;
  /**** Change Password *****/
   export const changePassword = `${serverUrl}/account/change-password`;
  /**** Forgot Password *****/
  export const forgotPassword = (email)=>`${serverUrl}/account/reset-password/init/${email}`;  
 /**** Reset Password *****/
  export const resetPassword = `${serverUrl}/account/reset-password/finish`;
  
  export const initiatePayment = `${serverUrl}/transaction/initiatePayment`;
  
  // Update Lead Details
  export const updateLeadDetails = `${serverUrl}/leads/updateSanctum`;
  export const topUpAPi = `${serverUrl}/secure-now/get-fhi-top-up-premium`;
  export const seniorAPI = `${serverUrl}/secure-now/get-fhi-senior_citizen-premium`;