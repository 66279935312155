import React, { Component } from "react";
import { Button, Card, CardBody, Col, Form, Row } from "reactstrap";
import logo from "../../../../src/assets/img/brand/securenow-logo.png";

export class PaymentStatus extends Component {
    constructor(props) {
        super(props);
        this.state={
            partner:'',
            coi_path:'',
            product: false
          }

    }
    componentDidMount(){
        var pageURL = new URL(window.location.href)
        const res = pageURL.href?.split('/')[5]
        const partner=localStorage.getItem("partner")?localStorage.getItem("partner"):null
        const coi_path=localStorage.getItem("coi_path")?localStorage.getItem("coi_path"):null
        // localStorage.removeItem("partner");
        // localStorage.removeItem("coi_path");
        if(res?.split("=")[0] === "dpiLegal" && res?.split("=")[1] === "true"){
            this.setState({
                product: true
            })
        }
        this.setState({
            partner: partner,
            coi_path: coi_path,
        })
    }

    redirectToDpiProposal=()=>{
        const id  = localStorage.getItem("web_txnRef")
        window.location.href =  process.env.REACT_APP_WEB_PAM_URL+`/retrieveTransactionStatus?transactionId=${id}&product=DoctorProfessionalIndemnity&isProposalFormSaved=false&insurerId=194`
    }

    render() {
        return (
            <div style={{ textAlign: 'center', overflowX: 'hidden' }}>
                <div>
                    <img src={'https://static-files-website.s3.ap-south-1.amazonaws.com/securenow_logo.svg'} alt="" style={{ padding: "20px 0" }} />
                </div>
                <Row>
                    <Col xs="12" sm="6" md={{ size: 6, offset: 3 }} offset="3">
                        <Card>
                            <CardBody>
                                <h4>Congratulations!</h4>
                                <h4 style={{color: '#19ab00'}}>Payment successful.</h4>
                                <p>We have sent the policy to you over email.<br></br>
                                    For any issues, please call on +91 9696683999</p>
                                {this.state.partner==='Ocare' || this.state.partner==='Floatr'?<a target="_blank" href={this.state.coi_path}>Download Certificate of Insurance</a>:null}
                                {this.state.product===true? <button onClick={this.redirectToDpiProposal} className="btn btn-primary"> Proceed to Proposal</button>: null}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        )
    }

}

export default PaymentStatus;