import React, { Component } from "react";
import PolicyPremium from "./../../Inputs/PolicyPremiumModal";
import InputTextField from "./../../Inputs/InputField";
import DropdownSelect from "./../../Inputs/SelectField";
import InputCheckboxField from "./../../Inputs/checkbox";
import DatePicker from "./../../Inputs/Datepicker";
import { Button, Card, CardBody, Col, Form, Row, Modal, ModalBody } from "reactstrap";
import axios from "axios";
import logo from "../../../../src/assets/img/brand/securenow-logo.png";
import { toast, ToastContainer } from "react-toastify";
import { initiatePayment, topUpAPi, seniorAPI } from "./../../../Shared/ApiConstants";
import staticLocalData from "../../../constants/static_data";
import "react-toastify/dist/ReactToastify.css";
import { Input } from "reactstrap";
import Specialization_Dpi from "./sub-components/Specialization_Dpi";
import './sub-components/Specialization_Dpi.css'
import static_data from "./sub-components/static_data";
import LoadingIndicator from "../../Ledger/LoadingIndicator";
import CustomSelectField from "../../Inputs/CustomSelectField";
import LegalAllInOneStepOne from "./sub-components/LegalAllInOneStepOne";
import MultiInvoiceFields from "./sub-components/MultiInvoiceFields";
import MedicalEstablishment from "./sub-components/MedicalEstablishment";
import FontAwesome from "../../Icons/FontAwesome/FontAwesome";
export class ProposalForm extends Component {
    constructor(props) {
        super(props);
        const path = window.location.href
        this.urlName = path.split("proposal-form/")[1]
        this.state = {
            loading: false,
            premiumpur: false,
            premiumsave: false,
            calcPremium: "",
            calcClick: false,
            purclick: false,
            calculatorId: "",
            calcList: [],
            errorMessage: '',
            insurerName: '',
            showPremium: false,
            sendCalcData: {
                GST_Number: '',
                Aadhar_Number: ''
            },
            showCalculatedPremium: false,
            btnType: '',
            setMaxDate: '',
            topUpCheck: false,
            topUpType: '',
            fhiCheck: false,
            enableButtonForFhi: false,
            showModal: false,
            alreadyShown: false,
            stateList: '',
            marineCheck: false,
            subCommodityList: [],
            fileUploadedFlag: false,
            fileUploadError: '',
            radioVal: 'individual',
            isSelectedFlag: false,
            isDCA: false,
            isLegalService: false,
            Subscription_Detail: '',
            charges_legal: '',
            legalPlus: '',
            isPA: false,
            maxDateAfterThreeYr: "",
            dpiLegal: false,
            buyType: 'Individual Practice',
            specialization: '',
            opdValue: '',
            ipdValue: '',
            subSpecialization: '',
            limitOfIndemnity: '30 Lacs',
            territory: 'India',
            prospectId: '',
            userCity: [],
            userSelectedCity: '',
            step: 1,
            assignedRmList: [],
            RM_NAME: '',
            rmNameString: '',
            loader: false,
            oldRateLegal: false,
            isHospitalService: false,
            LegalPlusHospitals: '',
            policyEndDate: new Date(),
            Policy_Start_Date: '',
            isBajajMarine: false,
            isCogoPort: false,
            isPartnerAgraga: false,
            isGxpress: false,
            incoTermsArr: [],
            Policy_Type: "",
            // Policy_Type: this.urlName === "Vaahak-Bajaj-Specific-Marine" ? "Inland" : "",
            windowUrl: "",
            legalAllInOne: false,
            allLegalStepOne: 1,
            legalAllInOnePreFilledData: {},
            isPartnerDroom: false,
            multiInvoiceData: [],
            disableBuyBtn: false,
            joeansInvoiceDate: '',
            isMedicalEstablishment:false,
            medicalEstablishmentPage:1,
        };
        this.calcount = 0;
        this.purcount = 0;
        this.urlName = '';
    }

    componentDidMount() {
        const path = window.location.href
        this.urlName = path.split("proposal-form/")[1]
        this.checkIfTopUp()
        this.checkIfFhi()
        this.checkIfMarine()
        this.fetchProposalFormFields(this.urlName)
        this.SetMaxDate()
        let stateListFinal = staticLocalData.stateList.sort((a, b) => {
            return a.STATE.localeCompare(b.STATE);
        });
        this.setState({
            stateList: stateListFinal
        })
        if (this.urlName === "Vaahak-Bajaj-Specific-Marine") {
            this.setState({ Policy_Type: "Inland" });
        }
    }

    checkIfTopUp = () => {
        let query = this.urlName.split("-")
        let queryName = query[query.length - 1]
        let topUpType = ''

        if (queryName == 'Topup') {
            if (query[1] == 'FHI') {
                topUpType = 'Family Top Up'
            }
            else {
                topUpType = 'Senior Citizen Top Up'
            }
            this.setState({
                topUpCheck: true,
                topUpType
            })
            return true
        }
        else {
            return false
        }
    }

    checkIfFhi = () => {
        if (this.checkIfTopUp()) {
            return false
        }
        else {
            console.log('check FHI')
            let queryName = this.urlName.split("-")[1]
            let queryName2 = this.urlName.split("-")[2]

            if (queryName == 'FHI' && queryName2 == 'Senior') {
                this.setState({
                    seniorCheck: true
                })
            }

            else if (queryName == 'FHI') {
                this.setState({
                    fhiCheck: true
                })
            }
        }
    }

    checkIfMarine = () => {
        var pageURL = new URL(window.location.href)
        let query = this.urlName.split("-")
        let queryName = query[query.length - 2] + '-' + query[query.length - 1]
        let checkForLegal = pageURL.href.split('/')[6]
        let checkForVaahak = pageURL.href.split('/')[6]

        this.setState({
            windowUrl: checkForVaahak
        })
        if (queryName == 'Specific-Marine') {
            this.setState({
                marineCheck: true,
            })
        }
        if (queryName.includes("DCA")) {
            this.setState({
                isDCA: true
            })
        }
        if (checkForLegal.toLowerCase().includes("legal") || checkForLegal.toLowerCase().includes("legal+")) {
            this.setState({
                isLegalService: true
            })
        }
        if (pageURL.href.split('/')[6].includes("PA") || checkForLegal.includes("SecureNowDCA-450") || checkForLegal.includes("DCA-450")) {
            this.setState({
                isPA: true,
            })
        }
        if (pageURL.href.split('/')[6].includes("securenow-dpi-legal+-service")) {
            this.setState({
                dpiLegal: true,
            }, () => this.initiatePaymentForDpi())
        }
        if (pageURL.href.split('/')[6].includes("securenow-legal-plus-services")) {
            this.setState({
                oldRateLegal: true
            })
        }
        if (checkForVaahak.includes("for-hospital-service")) {
            this.setState({
                isHospitalService: true
            })
        }
        if (pageURL.href.split('/')[6].includes("CogoPort-Bajaj-Specific-Marine")) {
            this.setState({
                isCogoPort: true
            })
        }
        if (checkForVaahak.includes("Bajaj-") && checkForVaahak.includes("Specific-") && checkForVaahak.includes("Marine")) {
            this.setState({
                isBajajMarine: true
            })
        }
        if (checkForVaahak.includes("Agraga-Bajaj-Specific-Marine")) {
            this.setState({
                isPartnerAgraga: true,
                incoTermsArr: [...staticLocalData.Incoterms_data],
            })
        }
        if (checkForVaahak.includes("Gxpress-Bajaj-Specific-Marine")) {
            this.setState({
                isGxpress: true,
                incoTermsArr: [...staticLocalData.Incoterms_data]
            })
        }
        if (checkForVaahak.includes("securenow-legal-all-in-one")) {
            this.setState({
                legalAllInOne: true
            })
        }
        if (checkForVaahak.includes("Droom-Bajaj-Specific-Marine")) {
            this.setState({
                isPartnerDroom: true
            })
        }
        if(checkForVaahak.includes("securenow-medical-establishment")){
            this.setState({
                isMedicalEstablishment: true
            })
        }
    }

    initiatePaymentForDpi = () => {
        const legalPremium = JSON.parse(localStorage.getItem("legal_premium"))
        console.log(this.props)
        const dpiPremiumWithGst = parseInt(this.props.dpiPremium) + this.calculateGst(parseInt(this.props.dpiPremium))
        if (this.props && this.props.details && this.props.dpiPremium > 0 && legalPremium.premium > 0) {
            this.setState({
                calcPremium: legalPremium,
                loader: true
            }, () => this.processToPaymentDpiLegal(dpiPremiumWithGst))
        }
        else {
            var url = process.env.REACT_APP_WEB_PAM_URL + '/pam/api/v1/rm/getAllActiveRM/'
            axios.get(url).then((res) => {
                this.setState({
                    assignedRmList: res.data.data
                })
            }).catch((err) => {
                toast.error(err.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                })
            })
        }
    }

    SetMaxDate = () => {
        let today = new Date();
        let dd = today.getDate();
        let mm = today.getMonth() + 1; //January is 0!
        let yyyy = today.getFullYear();
        let yyyyAfterThree = today.getFullYear() + 3;
        let oneDayBack = today.getDate() - 1

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }
        today = yyyy + '-' + mm + '-' + dd;
        let todayAfterThree = yyyyAfterThree + '-' + mm + '-' + dd
        this.setState({
            setMaxDate: today,
            maxDateAfterThreeYr: todayAfterThree,
            joeansInvoiceDate: yyyy + '-' + mm + '-' + oneDayBack
        })
    }

    fetchProposalFormFields = (urlName) => {
        let query = this.urlName.split("-")
        let queryName = query[query.length - 2] + '-' + query[query.length - 1]
        let url = ""
        if (queryName == 'Specific-Marine') {
            url = `/web/url-name/${urlName}?Client_Type=${this.state.radioVal}`
        }
        else {
            url = `/web/url-name/${urlName}?Client_Type=`
        }
        axios.get(process.env.REACT_APP_BASE_URL + url, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("auth-Token")}` // Add Authorization header
            }
        })
            .then(res => {
                console.log("page number: ",this.state.medicalEstablishmentPage)
                if(this.state.medicalEstablishmentPage===2){
                    this.handleFields(res,1);
                }else{
                    this.handleFields(res);
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    handleFields=(res, index=0)=>{
        this.setState({
            calcList: res.data.data.calculatorResponseDTOList[index].data,
            calculatorId: res.data.data.calculatorResponseDTOList[index].calculatorId,
            insurerName: res.data.data.insurerName,
            showPremium: res.data.data.showPremiumOnProposalForm
        }, () => this.setNonMandatory());

        for (let sumIns of this.state.calcList) {
            if (sumIns?.name === 'Sum_Assured') {
                this.setState({
                    sumInsured: sumIns.inputValue
                })
            }
        }
    }

    setNonMandatory = () => {
        var calcList = this.state.calcList;
        let tempData = { ...this.state.legalAllInOnePreFilledData }
        for (let ele of this.state.calcList) {
            if (ele?.name === 'PREMIUM' || ele?.name === 'Product' || ele?.name === 'Subscription_Provided' || ele?.name === 'Subscription_Period' || ele?.name === 'Lead_Type') {
                tempData[ele.name] = ele?.inputValue
                this.setState({
                    legalAllInOnePreFilledData: tempData
                })
            }
        }
        if (this.state.isMedicalEstablishment && this.state.medicalEstablishmentPage===2) {
            console.log("calc list: ",this.state.calcList);
            console.log("submit list: ",this.state.sendCalcData);
            // this.state.calcList[0].inputValue = this.state.sendCalcData.Product || "";
            this.state.calcList[9].inputValue = this.state.sendCalcData.Rate || "";
        }
        if(this.state.calcList[this.state.calcList.length-2].name=='Declaration'){
            console.log('Declaration')
            this.state.calcList[this.state.calcList.length - 2].mandatory = 'NON-MANDATORY'
        }
        this.setState({
            calcList
        })
    }

    validateEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        console.log('hiiii' + re.test(email?.toLowerCase()))
        return re.test(email?.toLowerCase());
    }

    validatePAN = (pan) => {
        let regForPan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        return (regForPan.test(pan))
    }

    validateForm = () => {
        this.validateEmail(this.state.Email)
        console.log(this.state.Email)
        var errorMessage = '';
        var gstRegax = /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}Z[0-9a-zA-Z]{1}$/;
        if (this.state.Phone_No && (this.state.Phone_No.length < 10 || this.state.Phone_No.length > 10 || isNaN(parseInt(this.state.Phone_No)))) {
            errorMessage = "Invalid Mobile number";
        }
        if (this.state.insurerName == 'Sanctum') {
            if (this.state.PAN && this.validatePAN(this.state.PAN) == false) {
                errorMessage = "Invalid PAN Number";
            }
        }
        if (this.state.isLegalService || this.state.oldRateLegal) {
            if (this.state.Pan_Number && this.validatePAN(this.state.Pan_Number) == false) {
                errorMessage = "Invalid PAN Number";
            }
        }
        if (this.state.phone && (this.state.phone.length < 10 || this.state.phone.length > 10 || isNaN(parseInt(this.state.phone)))) {
            errorMessage = "Invalid Mobile number";
        }
        if (this.state.Email && this.validateEmail(this.state.Email) == false) {
            console.log('i m here')
            errorMessage = "Invalid email address";
        }
        if (this.state.email && this.validateEmail(this.state.email) == false) {
            errorMessage = "Invalid email address";
        }
        if (this.state.gst && !gstRegax.test(this.state.gst)) {
            errorMessage = 'Invalid GST Number'
        }
        if (this.state.Gst_Number && !gstRegax.test(this.state.Gst_Number)) {
            errorMessage = 'Invalid GST Number'
        }
        if (this.state.dpiLegal && this.state.userSelectedCity === '') {
            errorMessage = 'Please select City'
        }
        if (this.state.dpiLegal && this.state.RM_NAME === '') {
            errorMessage = 'Please select Assigned RM Name'
        }
        if (this.state.isPartnerAgraga && !this.state.multiInvoiceData.length > 0) {
            errorMessage = "Please enter at least one entry of invoice data"
        }
        if (this.state.windowUrl.includes("Joeans-Logistics-Bajaj-Specific-Marine")) {
            if (!this.state.sendCalcData.Policy_Type) {
                errorMessage = "Please select policy type"
            }
            if (this.state.sendCalcData.Policy_Type !== "Inland" && !this.state.sendCalcData.Incoterms) {
                errorMessage = "Please select Incoterms"
            }
            else if (this.state.sendCalcData.Containerized === "Yes" && !this.state.sendCalcData.Number_Of_Containers) {
                errorMessage = "Please enter Number of Containers"
            }
            else if (!this.state.sendCalcData.CommodityId) {
                errorMessage = "Please select Commodity to be Insured"
            }
        }
        return errorMessage;
    }

    handleDpiPremiumFormSubmit = async (e) => {
        e.preventDefault();
        var formErrorMessage = this.validateForm();
        if (formErrorMessage != '') {
            this.setState({
                errorMessage: formErrorMessage
            })
            setTimeout(() => {
                this.setState({
                    errorMessage: ''
                })
            }, 2000)
            return;
        }
        else {
            let res = ''
            if (!this.state.fileUploadedFlag) {
                res = await this.uploadFiles()
                if (res != 200) {
                    return
                }
                else {
                    this.calculatePremiumForDpi()
                }
            }
            else {
                this.calculatePremiumForDpi()
            }
        }
    }

    calculatePremiumForDpi = async () => {
        let premium = this.state.sendCalcData;
        premium["PREMIUM"] = null;
        premium["orgLinkName"] = this.urlName;
        premium["calculatorId"] = this.state.calculatorId;
        premium['Pan_FilePath'] = this.state.sendCalcData.Pan_FilePath
        premium['Pan_Number'] = this.state.sendCalcData.Pan_Number ? this.state.sendCalcData.Pan_Number.toUpperCase() : ''
        premium["action"] = 'buy'
        premium['client_type'] = this.state.buyType ? this.state.buyType : ''
        premium['specialization'] = this.state.specialization ? this.state.specialization : ''
        premium['sub_Specialization'] = this.state.subSpecialization ? this.state.subSpecialization : ''
        premium['opd_Value'] = this.state.opdValue ? this.state.opdValue : ''
        premium['ipd_Value'] = this.state.ipdValue ? this.state.ipdValue : ''
        premium['sum_Insured'] = this.state.limitOfIndemnity ? this.state.limitOfIndemnity : ''
        premium['territory'] = this.state.territory ? this.state.territory : ""
        premium['RM_NAME'] = this.state.rmNameString ? this.state.rmNameString : ""

        if (this.state.windowUrl.includes("Joeans-Logistics-Bajaj-Specific-Marine")) {
            // Send Same date as Date of commencement of Voyage date in payload for Joeans partner
            premium['Policy_Start_Date'] = premium["Journey_Date"]
        }



        await axios.post(process.env.REACT_APP_BASE_URL + '/web/calculate/premium', premium)
            .then(response => {
                this.setState({
                    calcPremium: response.data,
                    premiumsave: true
                }, () => {
                    if (this.state.calcPremium.premium == -1 && this.state.calcPremium.message) {
                        alert(this.state.calcPremium.message)
                    }
                    else {
                        this.setState({
                            showCalculatedPremium: true,
                        }, () => this.createLead())
                    }
                })
            }).catch(error => {
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
    }

    handlePremiumFormSubmit = async (e) => {
        e.preventDefault();
        console.log("btn type", this.state.btnType)
        var formErrorMessage = this.validateForm();
        if (formErrorMessage != '') {
            this.setState({
                errorMessage: formErrorMessage
            })
            setTimeout(() => {
                this.setState({
                    errorMessage: ''
                })
            }, 2000)
            return;
        }
        let premium = this.state.sendCalcData;
        premium["action"] = this.state.btnType == 'PREMIUM' ? 'show' : 'buy'
        premium["orgLinkName"] = this.urlName;
        premium["calculatorId"] = this.state.calculatorId;
        premium["Sum_Assured"] = this.state.sendCalcData.Sum_Assured ? this.state.sendCalcData.Sum_Assured : this.state.sumInsured;
        premium['Pan_FilePath'] = this.state.sendCalcData.Pan_FilePath
        premium['Aadhar_FilePath'] = this.state.sendCalcData.Aadhar_FilePath
        premium['Gst_FilePath'] = this.state.sendCalcData.Gst_FilePath
        premium['Invoice_FilePath'] = this.state.sendCalcData.Invoice_FilePath
        premium['Client_Type'] = this.state.radioVal
        premium['Pan_Number'] = this.state.sendCalcData.Pan_Number ? this.state.sendCalcData.Pan_Number.toUpperCase() : ''
        premium['GST_Number'] = this.state.sendCalcData.GST_Number ? this.state.sendCalcData.GST_Number.toUpperCase() : ''
        // if(!this.state.fhiCheck && !this.state.seniorCheck && !this.state.topUpCheck){
        // premium["Sum_Assured"] = this.state.sumInsured;
        //  }  
        //  else{
        //     premium["Sum_Assured"]= this.state.sendCalcData.Sum_Assured
        //  }

        //Changes done For FHI

        if (!this.state.isBajajMarine && !this.state.legalAllInOne) {
            premium["PREMIUM"] = null;
        }
        // For vaahak and trucnetic to pass non manadatory fields in API
        if (this.state.isBajajMarine) {
            premium["Blawblrrrcngr_No"] = this.state.sendCalcData.Blawblrrrcngr_No ? this.state.sendCalcData.Blawblrrrcngr_No : ''
            premium["Blawblrrrcngr_Date"] = this.state.sendCalcData.Blawblrrrcngr_Date ? this.state.sendCalcData.Blawblrrrcngr_Date : ''
            premium["Lc_No"] = this.state.sendCalcData.Lc_No ? this.state.sendCalcData.Lc_No : ''
            premium["Marks_Containers_No"] = this.state.sendCalcData.Marks_Containers_No ? this.state.sendCalcData.Marks_Containers_No : ''
            premium["Carrier_Name"] = this.state.sendCalcData.Carrier_Name ? this.state.sendCalcData.Carrier_Name : ''
            premium["Voyage_No"] = this.state.sendCalcData.Voyage_No ? this.state.sendCalcData.Voyage_No : ''
            premium["Eway_Bill_No"] = this.state.sendCalcData.Eway_Bill_No ? this.state.sendCalcData.Eway_Bill_No : ''
            premium["Consignee_Name"] = this.state.sendCalcData.Consignee_Name ? this.state.sendCalcData.Consignee_Name : ''
            premium["Consignee_Mobile_No"] = this.state.sendCalcData.Consignee_Mobile_No ? this.state.sendCalcData.Consignee_Mobile_No : ''
            premium["Consignee_Address"] = this.state.sendCalcData.Consignee_Address ? this.state.sendCalcData.Consignee_Address : ''
            premium["Containerized"] = this.state.sendCalcData.Containerized ? this.state.sendCalcData.Containerized : ''
        }

        if (this.state.isHospitalService) {
            premium["Pan_Card_Type"] = this.state.sendCalcData.Insured_Type ? this.state.sendCalcData.Insured_Type : ''
            premium['Client_Type'] = ''
            premium['Policy_Start_Date'] = this.state.Policy_Start_Date
            premium['Policy_End_Date'] = this.state.policyEndDate
        }
        if (this.state.legalAllInOne) {
            premium["PREMIUM"] = this.state.legalAllInOnePreFilledData?.PREMIUM
            premium["Product"] = this.state.legalAllInOnePreFilledData?.Product
            premium["Subscription_Provided"] = this.state.legalAllInOnePreFilledData?.Subscription_Provided
            premium["Subscription_Period"] = this.state.legalAllInOnePreFilledData?.Subscription_Period
            premium["Lead_Type"] = this.state.legalAllInOnePreFilledData?.Lead_Type;
        }
        if(this.state.isMedicalEstablishment){
            premium["Product"] = this.state.calcList[0].inputValue || "";
            premium["Subscription_Period"] = "1 Year";
            premium["PREMIUM"] = String(parseFloat(this.state.sendCalcData.Rate) + parseFloat(this.calculateGst(this.state.sendCalcData.Rate)));
        }
        if(this.state?.isPartnerAgraga){
            premium["Invoice_Data"] = this.state.multiInvoiceData
        }
        if (this.state.windowUrl.includes("Joeans-Logistics-Bajaj-Specific-Marine")) {
            // Send Same date as date of commencement of voyage in payload for Joeans partner
            premium['Policy_Start_Date'] = premium["Journey_Date"]
        }
        if (this.state.fhiCheck && this.state.btnType === 'PREMIUM') {
            let selfChild = 'Self only'
            let sumInsured = this.state.Sum_Assured
            let age = this.state.Age

            if (this.state.Marital_status == "Married" && this.state.Child_Count == 0) {
                selfChild = "Self and Spouse"
            }
            else if (this.state.Marital_status == "Married" && this.state.Child_Count > 0) {
                selfChild = "Self, Spouse and " + this.state.Child_Count + " Child"
            }
            else if (this.state.Marital_status == "Single" && this.state.Child_Count > 0) {
                toast.error("Child count should be 0 for Unmarried person", {
                    position: toast.POSITION.TOP_CENTER
                });
                return false
            }

            var obj = {
                XLEW_1_5_4: age,
                XLEW_2_2_3: selfChild,
                XLEW_2_3_3: sumInsured
            }
            console.log(obj)
            axios.post(process.env.REACT_APP_PAM_URL + "/calc/familyHealthInsuranceCareHealthCalc", obj)
                .then(res => {
                    const calcPremium = {
                        premium: res.data.XLEW_2_6_3
                    }
                    this.setState({
                        showCalculatedPremium: true,
                        calcPremium,
                        enableButtonForFhi: true
                    }, () => {
                        console.log(this.state.enableButtonForFhi, this.state.showCalculatedPremium)
                    })
                }).catch(error => {
                    if (error.response) {
                        toast.error(error.response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
        }

        // changes for senoir

        else if (this.state.seniorCheck && this.state.btnType === 'PREMIUM') {
            const obj = {
                age: this.state.Age,
                member_count: 1,
                sum_insured: this.state.Sum_Assured
            }
            if (this.state.Marital_status == "Married") {
                obj.member_count = 2
            }
            axios.post(seniorAPI, obj).then(res => {
                console.log(res.data.error)
                if (res.data.error) {
                    alert(res.data.error)
                }
                else {
                    const calcPremium = {
                        premium: res.data.premium_amt
                    }
                    this.setState({
                        showCalculatedPremium: true,
                        calcPremium,
                        enableButtonForFhi: true
                    }, () => {
                        console.log(this.state.enableButtonForFhi, this.state.showCalculatedPremium)
                    })
                }
            })

        }
        // changes for TopUp

        else if (this.state.topUpCheck && this.state.btnType === 'PREMIUM') {
            console.log('inside if else')
            let memberCountArr = [this.state.insuredPersonFirstName1, this.state.insuredPersonFirstName2, this.state.insuredPersonFirstName3, this.state.insuredPersonFirstName4]
            let memberCount = 0
            for (let i = 0; i <= memberCountArr.length - 1; i++) {
                if (memberCountArr[i]) {
                    console.log(memberCountArr[i])
                    memberCount = memberCount + 1
                }
            }
            const obj = {
                age: this.state.max_age,
                plan_type: this.state.topUpType,
                member_count: memberCount,
                sum_insured: this.state.sumInsured,
                deductible_amt: this.state.deductible_amt
            }
            console.log(obj)
            axios.post(topUpAPi, obj).then(res => {
                const calcPremium = {
                    premium: res.data.premium_amt
                }
                this.setState({
                    showCalculatedPremium: true,
                    calcPremium,
                    enableButtonForFhi: true
                }, () => {
                    console.log(this.state.enableButtonForFhi, this.state.showCalculatedPremium)
                })
            })
        }
        else {
            this.setState({
                disableBuyBtn: true
            })
            let res = ''
            if((this.state.btnType === 'BUY' || this.state.btnType === 'Buy') && !this.state.fileUploadedFlag && (this.state.marineCheck || this.state.isDCA || this.state.isLegalService || this.state.oldRateLegal || this.state.isBajajMarine || this.state.isHospitalService || this.state.isMedicalEstablishment)){
                res = await this.uploadFiles()
                // await this.uploadFiles()  
                if (res != 200) {
                    return
                }
            }
            if (this.state.fhiCheck || this.state.topUpCheck || this.state.seniorCheck) {
                premium["PREMIUM"] = this.state.calcPremium.premium
            }
            if (this.state.windowUrl.includes("Vaahak-Bajaj-Specific-Marine")) {
                // Send Same date as Date of commencement of Voyage date in payload for Joeans partner
                premium['Policy_Type'] = "Inland"
            }
            console.log(this.state.seniorCheck, this.state.btnType, this.state.alreadyShown, this.state.sendCalcData.Declaration)
            if ((this.state.fhiCheck || this.state.seniorCheck || this.state.topUpCheck) && (this.state.btnType === 'BUY' || this.state.btnType === 'Buy') && !this.state.alreadyShown && (!this.state.sendCalcData.Declaration || this.state.sendCalcData.Declaration == undefined)) {
                console.log('i m here')
                this.showModal()
                return
            }
            await axios.post(process.env.REACT_APP_BASE_URL + '/web/calculate/premium', premium)
                .then(response => {
                    this.setState({
                        calcPremium: response.data,
                        premiumsave: true
                    }, () => {
                        if (this.state.btnType === 'PREMIUM') {
                            if (this.state.calcPremium.premium == -1 && this.state.calcPremium.message) {
                                alert(this.state.calcPremium.message)
                            }
                            else {
                                this.setState({
                                    showCalculatedPremium: true,
                                })
                            }
                        }
                        else if (this.state.calcPremium.premium == -1 && this.state.calcPremium.message) {
                            alert(this.state.calcPremium.message)
                        }
                        else if (this.state.calcPremium.premium > 0 && (this.state.btnType === 'BUY' || this.state.btnType === 'Buy')) {
                            this.setState({
                                showCalculatedPremium: false
                            })
                            this.processToPayment()
                        }
                    })
                }).catch(error => {
                    if (error.response) {
                        toast.error(error.response.data.message, {
                            position: toast.POSITION.TOP_CENTER
                        });
                    } else if (error.request) {
                        console.log(error.request);
                    } else {
                        console.log('Error', error.message);
                    }
                });
            this.setState({
                disableBuyBtn: false
            })
        }
    }

    uploadFiles = async () => {
        let formData = new FormData()
        formData.append("gstFilepath", this.state.sendCalcData.Gst_FilePath ? this.state.sendCalcData.Gst_FilePath : null)
        formData.append("panFilepath", this.state.sendCalcData.Pan_FilePath ? this.state.sendCalcData.Pan_FilePath : null)
        formData.append("aadhaarFilepath", this.state.sendCalcData.Aadhar_FilePath ? this.state.sendCalcData.Aadhar_FilePath : null)
        formData.append("orgName", this.state.windowUrl)

        if (this.state.isPartnerAgraga) {
            this.state.multiInvoiceData.forEach((file) => {
                formData.append(`invoiceFilepath`, file.Invoice_FilePath);
            });
        }
        else {
            formData.append("invoiceFilepath", this.state.sendCalcData.Invoice_FilePath ? this.state.sendCalcData.Invoice_FilePath : null)
        }
        const sendCalcData = this.state.sendCalcData;

        let temp = await axios.post(process.env.REACT_APP_BASE_URL + "/ekyc-file/upload", formData).then(async (res) => {
            if (this.state.isPartnerAgraga) {
                let tempMultiInvoiceData = [...this.state.multiInvoiceData]
                let updatedMultiInvoice = tempMultiInvoiceData.map((ele, index) => {
                    // Check if there's a corresponding file path in api res
                    if (res.data.Invoice_FilePaths[index]) {
                        return {
                            ...ele, // Spread existing properties of ele
                            Invoice_FilePath: res.data.Invoice_FilePaths[index] // Update with the new path from uploaded files
                        };
                    }
                    return ele; // Return as is if no corresponding file path
                });
                sendCalcData["Invoice_Data"] = updatedMultiInvoice
            }
            if ((this.state.isLegalService || this.state.oldRateLegal) && !this.state.isHospitalService) {
                sendCalcData['Pan_FilePath'] = await res.data.Pan_FilePath
            }
            else {
                sendCalcData['Pan_FilePath'] = await res.data.Pan_FilePath
                sendCalcData['Aadhar_FilePath'] = await res.data.Aadhar_FilePath
                sendCalcData['Gst_FilePath'] = await res.data.Gst_FilePath
                sendCalcData['Invoice_FilePath'] = await res.data.Invoice_FilePath
            }
            return res.status
        }).catch((err) => {
            if (err.response) {
                toast.error(err.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        })
        if (temp === 200) {
            this.setState({
                fileUploadedFlag: true,
                sendCalcData: sendCalcData
            })
            return temp
        }
        else {
            return null
        }
    }

    showModal = () => {
        if ((!this.state.sendCalcData.Declaration || this.state.sendCalcData.Declaration == undefined)) {
            this.setState({
                showModal: true,
                modal: true,
                alreadyShown: true
            })
        }
    }

    processToPaymentDpiLegal = (dpiPremiumWithGst) => {
        const quoteId = localStorage.getItem("QuoteId")
        const totalPrem = parseInt(this.state.calcPremium.premium) + dpiPremiumWithGst
        const sendData = {
            'premium': totalPrem,
            'legal_premium': parseInt(this.state.calcPremium.premium),
            'dpi_premium': parseInt(this.props.dpiPremium),
            'leadId': this.state.calcPremium.leadId,
            'quoteId': parseInt(quoteId),
            "option": 0,
            "rmLogin": "",
            "popUpVal": "popUpVal"
        }
        axios.post(initiatePayment, sendData)
            .then(res => {
                if (res.data.status == 200 && res.data.success == true) {
                    if (res.data.data.razorPayOrderId) {
                        let url = window.location.origin
                        localStorage.setItem("web_txnRef", res.data.data.pam_secureNowTxnRefNo)
                        this.setState({
                            loader: false
                        })
                        window.location.href = url + "/#/" + "razorPay/txnId=" + res.data.data.razorPayOrderId + "/txnRef=" + res.data.data.secureNowTxnRefId + `/dpiLegal=` + true

                    } else {
                        let url = window.location.origin
                        this.setState({
                            loader: false
                        })
                        window.location.href = url + "/#/" + "payment-success" + `?dpiLegal=${true}`
                    }
                }
            })
            .catch(error => {
                if (error.response) {
                    this.setState({
                        loader: false
                    })
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
    }

    processToPayment = () => {
        console.log(this.state);
        this.setState({ loading: true });
        let sendData = {
            'premium': this.state.calcPremium.premium,
            'leadId': this.state.calcPremium.leadId
        }
        axios.post(initiatePayment, sendData)
            .then(res => {
                if (res.data.status == 200 && res.data.success == true) {
                    this.setState({ loading: false });
                    if (res.data.data.razorPayOrderId) {
                        this.props.history.push({
                            pathname: "/razorPay/txnId=" + res.data.data.razorPayOrderId + "/txnRef=" + res.data.data.secureNowTxnRefId
                        })
                    } else {
                        this.props.history.push({
                            pathname: '/payment-success',
                        })
                    }
                }
            })
            .catch(error => {
                if (error.response) {
                    toast.error(error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER
                    });
                    this.setState({ loading: false });
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
            });

    }

    validateFile = (file) => {
        let errorMsg = ""
        const name = file?.name
        const acceptedFileTypes = ['pdf', 'jpeg', 'png', 'jpg', 'txt']
        if (!acceptedFileTypes?.includes(name?.toLowerCase().split(".")[1])) {
            errorMsg = "File format should be in pdf, jpeg, png, jpg, txt"
        }
        else if (file?.size <= 0) {
            errorMsg = "Please upload valid file"
        }
        else if (file?.size > 20000000) {
            errorMsg = "File size should not be greater than 20 Mb"
        }
        return errorMsg
    }

    handleFiles = e => {
        const file = e.target.files[0]
        const errorMsg = ''
        // this.validateFile(file)
        if (errorMsg === '') {
            const sendCalcData = this.state.sendCalcData;
            sendCalcData[e.target.name] = file
            this.setState({
                sendCalcData: sendCalcData,
                fileUploadedFlag: false,
                fileUploadError: ''
            })
        }
        else {
            toast.error(errorMsg)
            this.setState({
                fileUploadedFlag: false,
                fileUploadError: errorMsg
            })
        }
    }

    handleChange = e => {
        console.log(e.target.name)
        const sendCalcData = this.state.sendCalcData;
        if (e.target.name == 'Declaration') {
            sendCalcData[e.currentTarget.name] = e.currentTarget.checked
        }
        else if (e.target.name == 'Invoice_Value' && this.state.marineCheck) {
            sendCalcData[e.currentTarget.name] = e.currentTarget.value
            let sum_insured = e.currentTarget.value ? parseInt(e.currentTarget.value) + e.currentTarget.value * 10 / 100 : ''
            if (this.state.isPartnerDroom) {
                sum_insured = parseInt(e.currentTarget.value)
            }
            sendCalcData['Sum_Insured'] = sum_insured
            this.setState({
                Sum_Insured: sum_insured
            });
        }
        else if (e.target.name == 'Description_Of_Cargo' && this.state.marineCheck) {
            sendCalcData[e.currentTarget.name] = e.currentTarget.value
            const arr = []
            if (e.currentTarget.value == 'Automobiles/ Machinery/ Metals') {
                arr.push('Auto Spare Parts', 'Machinery Tools and Spares', 'All Types of Metal Products Excluding Precious Metals')
            }
            else if (e.currentTarget.value == 'General Merchandise and Plastic Products') {
                arr.push('Plastic Products', 'Stationary Items', 'Toys, Games & Sports Equipments', 'All types of FMCG Commodities')
            }
            else if (e.currentTarget.value == 'Electrical Goods') {
                if (this.state.isCogoPort) {
                    arr.push('Computer and Telecom Equipment and Peripherals', 'Electrical and Electronic Equipment and Spares', 'White Goods/ Large Appliance - AC, Fridge, LED, Washing Machine, Microwave, Dishwasher')
                }
                else {
                    arr.push('Computer and Telecom Equipment and Peripherals', 'Electrical and Electronic Equipment and Spares')
                }
            }
            else if (e.currentTarget.value == 'Fabric & Textile') {
                arr.push('All types of fabric and garments', 'Cotton Yarn')
            }
            else if (e.currentTarget.value == 'Food') {
                if (this.state.isCogoPort) {
                    arr.push('Processed food/edible items', 'Edible Vegetable or Animal Fats and Oils', 'Frozen Meat of All Kinds')
                }
                else {
                    arr.push('Processed food/edible items')
                }
            }
            else if (e.currentTarget.value == 'Glass/ceramic/other fragile items') {
                arr.push('Ceramic products and Tiles', 'Granite and Marble')
            }
            else if (e.currentTarget.value == 'Leather, Rubber & Wood') {
                arr.push('Furniture', 'Leather & Leather Goods', 'Rubber', 'Tyres')
            }
            else if (e.currentTarget.value == 'Pharma & Chemicals') {
                arr.push('All types of non hazardous chemicals', 'Pharma Products')
            }
            else if (e.currentTarget.value == 'General Merchandise/ Plastic/ Paper/ Stationary/ Sports Equipments ') {
                arr.push('Household items', 'Paper Products', 'Plastic Products', 'Stationary Items', "Toys, Games & Sports Equipments", 'All types of FMCG Commodities')
            }
            else if (e.currentTarget.value == 'Others') {
                if (this.state.isCogoPort) {
                    arr.push('Leather/Animal hides and skins including fur', 'Coal (Coke/ Coal/ Peat/ Lignite/Anthracite/ Metallurgical/ Charcoal)', 'Footwear', 'Fabric or synthetic flooring tiles and artificial grass including rugs, carpets and floormats',
                        'Containers', 'All types of Non-Refrigerated Container', 'Refrigerated Container', 'Cement (Basic Risk)')
                }
                else {
                    arr.push('Leather/Animal hides and skins including fur', 'Coal (Coke/ Coal/ Peat/ Lignite/Anthracite/ Metallurgical/ Charcoal)', 'Footwear', 'Fabric or synthetic flooring tiles and artificial grass including rugs, carpets and floormats')
                }
            }
            this.setState({
                subCommodityList: arr
            })
        }
        else if (e.target.name === "No_of_Years_of_Subscription" && (this.state.isLegalService || this.state.oldRateLegal)) {
            let testVar = e.target.value
            if (testVar.trim() == '1 Year') {
                if (this.state.dpiLegal) {
                    this.setState({
                        Subscription_Detail: '5 Legal Notices reply in 1 year,10 Legal Consultation/Advice free of Cost.',
                        legalPlus: 'DPI-Legal+ 1 year @3000+ (GST 18%)',
                    }, () => sendCalcData['Subscription_Detail'] = this.state.Subscription_Detail)
                }
                else if (this.state.oldRateLegal) {
                    this.setState({
                        Subscription_Detail: '5 Legal Notices reply in 1 year,10 Legal Consultation/Advice free of Cost.',
                        legalPlus: 'DPI-Legal+ 1 year @3000+ (GST 18%)'
                    }, () => sendCalcData['Subscription_Detail'] = this.state.Subscription_Detail)
                }
                else if (this.state.isHospitalService) {
                    this.setState({
                        Subscription_Detail: '5 legal Notices reply in a year, unlimited legal consultation/advice free of Cost',
                        LegalPlusHospitals: 'Legal+ for Hospitals 1 year @16000+ (GST 18%)',
                        Policy_Start_Date: '',
                        policyEndDate: ''
                    }, () => {
                        sendCalcData['Subscription_Detail'] = this.state.Subscription_Detail
                        sendCalcData['Policy_Start_Date'] = ""
                    })
                }
                else {
                    this.setState({
                        Subscription_Detail: '5 Legal Notices reply in 1 year,10 Legal Consultation/Advice free of Cost.',
                        legalPlus: 'Legal+ 1 year @5000+ (GST 18%)'
                    }, () => sendCalcData['Subscription_Detail'] = this.state.Subscription_Detail)
                }
            }
            else if (testVar.trim() == '2 Years') {
                if (this.state.dpiLegal) {
                    this.setState({
                        Subscription_Detail: '10 Legal Notices reply in 2 years, 20 Legal Consultation/Advice free of Cost.',
                        legalPlus: 'DPI-Legal+ 2 years @5000+ (GST 18%)'
                    }, () => sendCalcData['Subscription_Detail'] = this.state.Subscription_Detail)
                }
                else if (this.state.oldRateLegal) {
                    this.setState({
                        Subscription_Detail: '10 Legal Notices reply in 2 years, 20 Legal Consultation/Advice free of Cost.',
                        legalPlus: 'DPI-Legal+ 2 years @5000+ (GST 18%)'
                    }, () => sendCalcData['Subscription_Detail'] = this.state.Subscription_Detail)
                }
                else if (this.state.isHospitalService) {
                    this.setState({
                        Subscription_Detail: '10 legal Notices reply in two years, unlimited l legal consultation/advice free of Cost.',
                        LegalPlusHospitals: 'Legal+ for Hospitals 2 year @26000+ (GST 18%)',
                        Policy_Start_Date: '',
                        policyEndDate: ''
                    }, () => {
                        sendCalcData['Subscription_Detail'] = this.state.Subscription_Detail
                        sendCalcData['Policy_Start_Date'] = ""
                    })
                }
                else {
                    this.setState({
                        Subscription_Detail: '10 Legal Notices reply in 2 years, 20 Legal Consultation/Advice free of Cost.',
                        legalPlus: 'Legal+ 2 years @7000+ (GST 18%)'
                    }, () => sendCalcData['Subscription_Detail'] = this.state.Subscription_Detail)
                }
            }
            else if (testVar.trim() == '3 Years') {
                if (this.state.dpiLegal) {
                    this.setState({
                        Subscription_Detail: '20 Legal Notices reply in 3 years, Unlimited Legal Consultation/Advice free for all medico-legal cases.',
                        legalPlus: 'DPI-Legal+ 3 year @7000+ (GST 18%)'
                    }, () => sendCalcData['Subscription_Detail'] = this.state.Subscription_Detail)
                }
                else if (this.state.oldRateLegal) {
                    this.setState({
                        Subscription_Detail: '20 Legal Notices reply in 3 years, Unlimited Legal Consultation/Advice free for all medico-legal cases.',
                        legalPlus: 'DPI-Legal+ 3 year @7000+ (GST 18%)'
                    }, () => sendCalcData['Subscription_Detail'] = this.state.Subscription_Detail)
                }
                else if (this.state.isHospitalService) {
                    this.setState({
                        Subscription_Detail: '20 legal Notices reply in three years, Unlimited legal consultation/advice free for all medico-legal cases.',
                        LegalPlusHospitals: 'Legal+ for Hospitals 3 year @33000+ (GST 18%)',
                        Policy_Start_Date: '',
                        policyEndDate: ''
                    }, () => {
                        sendCalcData['Subscription_Detail'] = this.state.Subscription_Detail
                        sendCalcData['Policy_Start_Date'] = ""
                    })
                }
                else {
                    this.setState({
                        Subscription_Detail: '20 Legal Notices reply in 3 years, Unlimited Legal Consultation/Advice free for all medico-legal cases.',
                        legalPlus: 'Legal+ 3 year @9000+ (GST 18%)'
                    }, () => sendCalcData['Subscription_Detail'] = this.state.Subscription_Detail)
                }
            }
            else if (testVar.trim() == '5 Years') {
                if (this.state.isHospitalService) {
                    this.setState({
                        Subscription_Detail: '30 legal Notices reply in five years, Unlimited legal consultation/advice free for all medico-legal cases.',
                        LegalPlusHospitals: 'Legal+ for Hospitals 5 year @45000+ (GST 18%)',
                        Policy_Start_Date: '',
                        policyEndDate: ''
                    }, () => {
                        sendCalcData['Subscription_Detail'] = this.state.Subscription_Detail
                        sendCalcData['Policy_Start_Date'] = ""
                    })
                }
            }
            sendCalcData['No_of_Years_of_Subscription'] = testVar
        }
        else if (this.state.dpiLegal && e.target.name === "Pin_Code") {
            this.setState({
                [e.currentTarget.name]: e.currentTarget.value,
            }, () => this.getCityList());
            sendCalcData['Pin_Code'] = e.currentTarget.value
        }
        else if (
            this.state.isHospitalService &&
            e.target.name === "Policy_Start_Date" &&
            this.state.sendCalcData?.No_of_Years_of_Subscription
        ) {
            const currentDate = new Date(e.target.value);
            let endDate;

            if (!isNaN(currentDate.getTime())) {
                if (currentDate) {
                    // Subtract one day from the start date
                    currentDate.setDate(currentDate.getDate() - 1);

                    if (this.state.sendCalcData?.No_of_Years_of_Subscription === "1 Year") {
                        endDate = new Date(currentDate);
                        endDate.setFullYear(endDate.getFullYear() + 1); // Adding 1 year
                    } else if (this.state.sendCalcData?.No_of_Years_of_Subscription === "2 Years") {
                        endDate = new Date(currentDate);
                        endDate.setFullYear(endDate.getFullYear() + 2); // Adding 2 years
                    } else if (this.state.sendCalcData?.No_of_Years_of_Subscription === "3 Years") {
                        endDate = new Date(currentDate);
                        endDate.setFullYear(endDate.getFullYear() + 3); // Adding 3 years
                    } else if (this.state.sendCalcData?.No_of_Years_of_Subscription === "5 Years") {
                        endDate = new Date(currentDate);
                        endDate.setFullYear(endDate.getFullYear() + 5); // Adding 5 years
                    }

                    const formattedEndDate = endDate ? endDate?.toISOString().split('T')[0] : '';

                    this.setState({
                        [e.currentTarget.name]: e.currentTarget.value,
                        policyEndDate: formattedEndDate,
                    });
                }
            }
        }
        else if (e.target.name === "Policy_Type") {
            let temp = [...staticLocalData.Incoterms_data]
            let indexToUpdate = temp.findIndex(ele => ele.id === "CIF");

            if (e.currentTarget.value === "Import") {
                if (indexToUpdate !== -1) {
                    temp.splice(indexToUpdate, 1, { id: "", name: "Cost, Insurance And Freight(CIF)" }); // Remove existinf CIF option and add blank id to disable it
                }
            }
            if (this.state.windowUrl.includes("Joeans-Logistics-Bajaj-Specific-Marine")) {
                sendCalcData["Incoterms"] = ""
            }
            sendCalcData[e.currentTarget.name] = e.currentTarget.value
            this.setState({
                [e.currentTarget.name]: e.currentTarget.value,
                incoTermsArr: temp
            })
        }
        else {
            sendCalcData[e.currentTarget.name] = e.currentTarget.value
        }

        this.setState({
            [e.currentTarget.name]: e.currentTarget.value,
            sendCalcData,
        });
        if (this.state.fhiCheck) {
            this.setState({
                enableButtonForFhi: false
            })
        }
    };

    handleCommodityDropdown = (name, val, optName) => {
        const sendCalcData = this.state.sendCalcData;
        sendCalcData[name] = val
        this.setState({
            [name]: val,
            commodityOption: optName,
            sendCalcData,
        })
    }

    handleRmNameChange = (e) => {
        const selectedOptionText = e.target.options[e.target.selectedIndex].text;
        console.log(selectedOptionText)
        this.setState({
            RM_NAME: e.target.value,
            rmNameString: selectedOptionText
        });
    }

    getCityList = () => {
        if (this.state.Pin_Code && this.state.Pin_Code.length == 6) {
            let url = `/profession/api/v1/common/pincode?pin=`
            axios.get(process.env.REACT_APP_WEB_PAM_URL + url + this.state.Pin_Code).then((res) => {
                this.setState({
                    userCity: res.data.data.city,
                })
            })
        }
    }

    hideModal = () => {
        this.setState({
            modal: !this.state.modal,
            showModal: false,
            alreadyShown: false
        })
    }

    handleRadioChange = (e) => {
        this.setState({
            radioVal: e.target.value,
            isSelectedFlag: false
        })
    }

    onSubmitOfRadio = () => {
        this.setState({
            isSelectedFlag: true
        }, () => this.fetchProposalFormFields(this.urlName))
    }

    calculateGst = (val) => {
        const gst = (val * 18) / 100
        return gst
    }

    sortList = (list) => {
        let tempRes = list.split("||").sort((a, b) => a > b ? 1 : -1)
        return tempRes.join("||")
    }

    handleSelectChange = (e) => {
        if (e.target.name === "specialization") {
            this.setState({
                specialization: e.target.value,
                subSpecialization: ''
            })
            if (e.target.value === 'Anesthetist') {
                this.setState({
                    subSpecialization: 'Critical care medicine with Anesthesia'
                })
            }
            else if (e.target.value === 'Radiologist') {
                this.setState({
                    subSpecialization: 'Consultant Radiologist'
                })
            }
            else if (e.target.value === 'General Physician') {
                this.setState({
                    subSpecialization: 'General Physician, MBBS'
                })
            }
            else if (e.target.value === 'Pathologist') {
                this.setState({
                    subSpecialization: 'Pathologist, MBBS'
                })
            }
            else
                this.setState({
                    subSpecialization: ''
                })
        }
        else if (e.target.name === "limitOfIndemnity") {
            this.setState({
                limitOfIndemnity: e.target.value
            })
        }
        else if (e.target.name === "territory") {
            this.setState({
                territory: e.target.value
            })
        }
        else
            this.setState({
                subSpecialization: e.target.value
            })
    }

    handleInputChange = (e) => {
        if (e.target.name === "opdValue") {
            this.setState({
                opdValue: e.target.value
            })
        }
        else if (e.target.name === "ipdValue") {
            this.setState({
                ipdValue: e.target.value
            })
        }
    }

    handleRadioChangeLegal = (e) => {
        this.setState({
            buyType: e.target.value,
            specialization: '0',
            subSpecialization: '',
            opdValue: '',
            ipdValue: ''
        })
    }

    createLead = () => {
        let data = this.state.sendCalcData
        var pageURL = new URL(window.location.href)
        let reqData = {
            company: data.First_Name + ' ' + data.Last_Name,
            location: this.state.userSelectedCity,
            mobile: parseInt(data.Phone_No),
            email: data.Email,
            product: 83,
            channel: "Partners",
            prospectId: "",
            partnerId: 1116,
            isSuccess: "",
            leadSource: window.location.href,
            rmId: this.state.RM_NAME,
            googleClickID: pageURL.searchParams.get("gclid") ? pageURL.searchParams.get("gclid") : ""
        }
        axios.post(process.env.REACT_APP_WEB_PAM_URL + `/profession/api/v1/createOrUpdateProspect`, reqData).then(res => {
            const calcData = JSON.stringify(this.state.calcPremium)
            localStorage.setItem("legal_premium", calcData)
            this.setState({
                prospectId: res.data.data.prospectId
            }, () => {
                this.setState({
                    step: 2
                })
            })
        }).catch((Err) => console.log(Err))
    }

    handleLegalAllInOneStepUpdate = (step, response) => {
        this.setState({
            allLegalStepOne: step
        }, () => this.handleFields(response))
    }

    handleMedicalEstablishmentStepUpdate=(step)=>{
        this.setState({
            medicalEstablishmentPage: step
        },()=> this.fetchProposalFormFields(this.urlName))
    }

    handleMedicalEstablishmentStepUpdate=(step)=>{
        this.setState({
            medicalEstablishmentPage: step
        },()=> this.fetchProposalFormFields(this.urlName))
    }

    getMultiInvoiceData = (invoiceData) => {
        this.setState({
            multiInvoiceData: invoiceData
        })
    }

    render() {
        return (
            <>
            {this.state.dpiLegal && this.state.step===2?
            <Specialization_Dpi 
                clientType={this.state.sendCalcData.client_type} 
                special={this.state.sendCalcData.specialization} 
                subSpecial={this.state.sendCalcData.sub_Specialization}
                sumInsured={this.state.limitOfIndemnity}
                terri={this.state.sendCalcData.territory}
                prospect={this.state.prospectId}
                opd={this.state.opdValue}
                ipd={this.state.ipdValue}
                    /> : this.state.isMedicalEstablishment && this.state.medicalEstablishmentPage===1 ? (
                        <MedicalEstablishment
                            inputData={this.state.calcList}
                            insurerName={this.state.insurerName}
                            updateStep={(step) => this.handleMedicalEstablishmentStepUpdate(step)}
                            mainHandleChange={(e)=>this.handleChange(e)}
                        />
                    )
                        :
                        <>
            {!this.state.loader ?
            this.state.legalAllInOne && this.state.allLegalStepOne === 1? 
            <LegalAllInOneStepOne 
                inputData={this.state.calcList} 
                updateStep={(data, response)=>this.handleLegalAllInOneStepUpdate(data, response)}
                insurerName={this.state.insurerName}
            />:
            <div>

                <div style={{ textAlign: "center" }}>
                    <img src={'https://static-files-website.s3.ap-south-1.amazonaws.com/securenow_logo.svg'} alt="" style={{ padding: "20px 0" }} />
                </div>
                <Col xs="12" sm={{ size: 8, offset: 2 }} md={{ size: 6, offset: 3 }}>

                    <h4 className="text-center">{!this.state.isPA ?
                     <span>Proposal Form for <br/> {this.state.insurerName}</span> :null}</h4>
                    <Card>
                                                {this.state.isMedicalEstablishment && (
                                                    <div
                                                        onClick={() => this.handleMedicalEstablishmentStepUpdate(1)}
                                                    >
                                                        <i style={{ fontSize: "30px", cursor: "pointer" }} className="fa fa-chevron-circle-left fa-lg ml-2 mt-2"></i>
                                                    </div>
                                                )}
                        <Form className="form" onSubmit={this.state.dpiLegal? null : this.handlePremiumFormSubmit}>
                            <CardBody>
                            {this.state.isPA?
                            <p className="text-center text-danger" style={{fontSize:'18px'}}><b>This URL is no longer active</b></p>:
                                this.state.marineCheck && !this.state.isSelectedFlag?
                                <>
                                    <div className="row">
                                        <p className="col-12" style={{ fontSize: '16px', color: '#005bab', fontWeight: '700' }}>Choose One Option</p>
                                        <div className='radio-input pl-4 pr-6 col-6'>
                                            <input className="form-check-input position-static"
                                                type="radio"
                                                name='Individual/Corporate'
                                                value='individual'
                                                checked={this.state.radioVal === 'individual' ? true : false}
                                                onChange={this.handleRadioChange}
                                            />
                                            <label className='pl-2'>Individual/Micro Enterprises</label>
                                            <p style={{ color: '#979797', fontSize: '12px' }}>Business that do not require GST</p>
                                        </div>

                                                                    <div className='radio-input pl-4 pr-6 col-6'>
                                                                        <input className="form-check-input position-static"
                                                                            type="radio"
                                                                            name='Individual/Corporate'
                                                                            value='corporate'
                                                                            checked={this.state.radioVal === 'corporate' ? true : false}
                                                                            onChange={this.handleRadioChange}
                                                                        />
                                                                        <label className='pl-2'>Corporate/Business</label>
                                                                        <p style={{ color: '#979797', fontSize: '12px' }}>Firms who have a GST registration</p>
                                                                    </div>
                                                                    {this.state.isBajajMarine ?
                                                                        <div className="border rounded p-2 mt-2 m-1 bg-light">
                                                                            <p disabled={true} style={{ color: '##707070', fontSize: '13px' }}>Important: Please take due care while choosing your profile. It will help in smooth processing of your
                                                                                request and can impact admissibility of future claims, if any.</p>
                                                                        </div> : null}
                                                                    <Button color="primary" type="submit" className="mb-4 mt-3 ml-4" onClick={this.onSubmitOfRadio}> Submit </Button>
                                                                </div>
                                                            </> :
                                                            <>
                                                                {this.state.calcList.map((form, i) => {
                                                                    if (
                                                                        (form?.inputType === "NUMBER-DROPDOWN" || form?.inputType === "VARCHAR-DROPDOWN") &&
                                                                        form?.display === "YES"
                                                                    ) {
                                                                        if (this.state.marineCheck && form.name === 'Sub_Commodity') {
                                                                            return (
                                                                                <div className="form-group" key={i}>
                                                                                    <label className={form.mandatory === "MANDATORY" ? "required" : ""}>{form.description}</label>
                                                                                    <Input
                                                                                        type="select"
                                                                                        required={form.mandatory === "MANDATORY"}
                                                                                        name={form.name}
                                                                                        bsSize="sm"
                                                                                        onChange={this.handleChange}
                                                                                    >
                                                                                        <option value="">Select {form.description}</option>
                                                                                        {this.state.subCommodityList?.map((item, i) => (
                                                                                            <option key={i} value={item}>
                                                                                                {item}
                                                                                            </option>
                                                                                        ))}
                                                                                    </Input>
                                                                                </div>
                                                                            )
                                                                        }
                                                                        if (form.name === "Incoterms" && this.state.Policy_Type && this.state.Policy_Type !== "Inland" &&
                                                                            (this.state.isPartnerAgraga || this.state.isGxpress ||
                                                                                this.state.windowUrl.includes("Trukky-Bajaj-Specific-Marine") ||
                                                                                this.state.windowUrl.includes("Joeans-Logistics-Bajaj-Specific-Marine"))
                                                                        ) {
                                                                            return (
                                                                                <DropdownSelect
                                                                                    key={i}
                                                                                    name={form.name}
                                                                                    label={form.description}
                                                                                    placeholder={form.description}
                                                                                    required={form.mandatory === "MANDATORY"}
                                                                                    handleChange={this.handleChange}
                                                                                    val={this.state.incoTermsArr}
                                                                                    value={this.state.sendCalcData?.Incoterms || ''}
                                                                                    selectname={form.description}
                                                                                    className={form.mandatory === "MANDATORY" ? "required" : ""}
                                                                                    isFlag={""}
                                                                                />
                                                                            );
                                                                        }
                                                                        if (
                                                                            (this.state.isPartnerAgraga || this.state.isGxpress ||
                                                                                (this.state.windowUrl && this.state.windowUrl.includes("Gxpress-Bajaj-Specific-Marine")) ||
                                                                                (this.state.windowUrl && this.state.windowUrl.includes("Joeans-Logistics-Bajaj-Specific-Marine"))) &&
                                                                            form.name === "CommodityId"
                                                                        ) {
                                                                            const allowedIds = form.inputValue.split("||");

                                                                            const filteredOptions = staticLocalData.agraga_commodity_data.filter(option =>
                                                                                allowedIds.includes(option.id)
                                                                            );

                                                                            return (
                                                                                <CustomSelectField
                                                                                    key={i}
                                                                                    optionsList={filteredOptions}
                                                                                    label={form.description}
                                                                                    Name={form.name}
                                                                                    required={form.mandatory === "MANDATORY" || this.state.windowUrl.includes("Joeans-Logistics-Bajaj-Specific-Marine")}
                                                                                    selectedOption={this.state.commodityOption}
                                                                                    handleOptionSelect={(Name, value, optName) => this.handleCommodityDropdown(Name, value, optName)}
                                                                                />
                                                                            );
                                                                        }
                                                                        if ((this.state.isPartnerAgraga || this.state.isGxpress ||
                                                                            this.state.windowUrl.includes("Trukky-Bajaj-Specific-Marine") ||
                                                                            this.state.windowUrl.includes("Joeans-Logistics-Bajaj-Specific-Marine"))
                                                                            && (form.name === "Incoterms" || form.name === "CommodityId")) {
                                                                            return null;
                                                                        }
                                                                        else {
                                                                            return (
                                                                                // <DropdownSelect
                                                                                //     key={i}
                                                                                //     name={form.name}
                                                                                //     label={form.description}
                                                                                //     placeholder={form.description}
                                                                                //     required={form.mandatory === "MANDATORY"}
                                                                                //     handleChange={this.handleChange}
                                                                                //     val={form.name === "RM_NAME" ? this.sortList(form.inputValue) : form.inputValue}
                                                                                //     selectname={form.description}
                                                                                //     className={form.mandatory === "MANDATORY" ? "required" : ""}
                                                                                //     isFlag={"dynamic"}
                                                                                // />
                                                                                <DropdownSelect
                                                                                    key={i}
                                                                                    name={form.name}
                                                                                    label={form.description}
                                                                                    placeholder={form.description}
                                                                                    required={form.mandatory === "MANDATORY"}
                                                                                    handleChange={this.handleChange}
                                                                                    val={form.name === "RM_NAME" ? this.sortList(form.inputValue) : form.inputValue}
                                                                                    selectname={form.description}
                                                                                    className={form.mandatory === "MANDATORY" ? "required" : ""}
                                                                                    isFlag={"dynamic"}
                                                                                    disabled={this.urlName === "Vaahak-Bajaj-Specific-Marine" && form.description === "Type of Single Transit Policy" ? true : false}
                                                                                    url={this.urlName}
                                                                                // value={this.state.Policy_Type}
                                                                                />

                                                                            );
                                                                        }
                                                                    }
                                                                    if (form?.inputType === "NUMBER" && form?.display === "YES") {
                                                                        return (
                                                                            <InputTextField
                                                                                type={"number"}
                                                                                key={i}
                                                                                name={form.name}
                                                                                label={form.description}
                                                                                placeholder={form.description}
                                                                                required={form.mandatory}
                                                                                disable={form.inputBy}
                                                                                prefilled={form.inputValue}
                                                                                handleChange={this.handleChange}
                                                                                className={
                                                                                    form.mandatory === "MANDATORY" ? "required" : ""
                                                                                }
                                                                            />
                                                                        );
                                                                    }
                                                                    if (
                                                                        form?.inputType === "DATE" && form?.display === "YES" && (form?.name == "Policy_Start_Date") && (this.state.isHospitalService)
                                                                    ) {
                                                                        return (
                                                                            <DatePicker
                                                                                maxDate={this.state.maxDateAfterThreeYr}
                                                                                minDate={this.state.setMaxDate}
                                                                                key={i}
                                                                                name={form.name}
                                                                                label={form.description}
                                                                                placeholder={form.description}
                                                                                required={form.mandatory}
                                                                                className={
                                                                                    form.mandatory === "MANDATORY" ? "required" : ""
                                                                                }
                                                                                handleChange={this.handleChange}
                                                                                value={this.state.Policy_Start_Date}
                                                                                disabled={false}
                                                                            />
                                                                        );
                                                                    }
                                                                    if ((form?.name == 'Invoice_Date') && form?.inputType === "DATE" && form?.display === "YES" && this.state.isBajajMarine) {
                                                                        if (form.name === "Invoice_Date" && this.state.isPartnerAgraga) {
                                                                            return (
                                                                                <MultiInvoiceFields
                                                                                    maxDate={this.state.setMaxDate}
                                                                                    setMultiInvoiceData={(data) => this.getMultiInvoiceData(data)}
                                                                                />
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <DatePicker
                                                                                    maxDate={this.state.setMaxDate}
                                                                                    minDate={this.state.windowUrl.includes("Joeans-Logistics-Bajaj-Specific-Marine") ? this.state.joeansInvoiceDate : null}
                                                                                    key={i}
                                                                                    name={form.name}
                                                                                    label={form.description}
                                                                                    placeholder={form.description}
                                                                                    required={form.mandatory}
                                                                                    className={
                                                                                        form.mandatory === "MANDATORY" ? "required" : ""
                                                                                    }
                                                                                    handleChange={this.handleChange}
                                                                                    onkeyDown={(e) => e.preventDefault()}
                                                                                />
                                                                            )
                                                                        }
                                                                    }
                                                                    if ((form?.name == 'Effective_Date_Of_Coverage' || form?.name == 'Journey_Date' || form?.name == 'Invoice_Date' || form?.name != "Policy_End_Date") && form?.inputType === "DATE" &&
                                                                        form?.display === "YES") {
                                                                        return (
                                                                            <DatePicker
                                                                                // maxDate={this.state.setMaxDate}
                                                                                minDate={this.state.windowUrl.includes("Joeans-Logistics-Bajaj-Specific-Marine") ? this.state.setMaxDate : null}
                                                                                key={i}
                                                                                name={form.name}
                                                                                label={form.description}
                                                                                placeholder={form.description}
                                                                                required={form.mandatory}
                                                                                className={
                                                                                    form.mandatory === "MANDATORY" ? "required" : ""
                                                                                }
                                                                                handleChange={this.handleChange}
                                                                            />
                                                                        )
                                                                    }
                                                                    if (
                                                                        form?.inputType === "DATE" &&
                                                                        form?.display === "YES" && (form?.name !== 'Effective_Date_Of_Coverage' && form?.name !== 'Journey_Date' && form?.name !== 'Invoice_Date' && form?.name != "Policy_End_Date")
                                                                    ) {
                                                                        return (
                                                                            <DatePicker
                                                                                maxDate={this.state.setMaxDate}
                                                                                key={i}
                                                                                name={form.name}
                                                                                label={form.description}
                                                                                placeholder={form.description}
                                                                                required={form.mandatory}
                                                                                className={
                                                                                    form.mandatory === "MANDATORY" ? "required" : ""
                                                                                }
                                                                                handleChange={this.handleChange}
                                                                            />
                                                                        );
                                                                    }
                                                                    if (
                                                                        form?.inputType === "DATE" && (this.state.isLegalService || this.state.oldRateLegal) &&
                                                                        form?.display === "YES" && (form?.name == "Policy_End_Date") && (!this.state.isHospitalService)
                                                                    ) {
                                                                        return (
                                                                            <DatePicker
                                                                                maxDate={this.state.maxDateAfterThreeYr}
                                                                                key={i}
                                                                                name={form.name}
                                                                                label={form.description}
                                                                                placeholder={form.description}
                                                                                required={form.mandatory}
                                                                                className={
                                                                                    form.mandatory === "MANDATORY" ? "required" : ""
                                                                                }
                                                                                handleChange={this.handleChange}
                                                                            />
                                                                        );
                                                                    }

                                                                    if (
                                                                        form?.inputType === "DATE" && form?.display === "YES" && (form?.name == "Policy_End_Date") && (this.state.isHospitalService)
                                                                    ) {
                                                                        return (
                                                                            <DatePicker
                                                                                maxDate={this.state.maxDateAfterThreeYr}
                                                                                key={i}
                                                                                name={form.name}
                                                                                label={form.description}
                                                                                placeholder={form.description}
                                                                                required={form.mandatory}
                                                                                className={
                                                                                    form.mandatory === "MANDATORY" ? "required" : ""
                                                                                }
                                                                                handleChange={this.handleChange}
                                                                                value={this.state.policyEndDate}
                                                                                disabled={true}
                                                                            />
                                                                        );
                                                                    }
                                                                    if (
                                                                        form?.inputType === "VARCHAR" &&
                                                                        form?.display === "YES"
                                                                    ) {
                                                                        if (this.state.marineCheck && form?.name == 'State') {
                                                                            return (
                                                                                <div className="form-group">
                                                                                    <label className={form.mandatory === "MANDATORY" ? "required" : ""}>{form.description}</label>
                                                                                    <Input
                                                                                        type="select"
                                                                                        required={form.mandatory ? true : false}
                                                                                        name={form.name}
                                                                                        bsSize="sm"
                                                                                        onChange={this.handleChange}
                                                                                    //   value={value}
                                                                                    >
                                                                                        <option value="">Select {form.description}</option>
                                                                                        {this.state.stateList.map((item, i) => (
                                                                                            <option key={i} value={item.STATE}>
                                                                                                {item.STATE}
                                                                                            </option>
                                                                                        ))}
                                                                                    </Input>
                                                                                </div>
                                                                            );
                                                                        }
                                                                        else if (this.state.marineCheck && form?.name == 'Invoice_Value') {
                                                                            return (
                                                                                <div className="form-group">
                                                                                    <label className={form.mandatory === "MANDATORY" ? "required" : ""}>{form.description}</label>
                                                                                    <Input
                                                                                        type="number"
                                                                                        required={form.mandatory ? true : false}
                                                                                        name={form.name}
                                                                                        bsSize="sm"
                                                                                        onChange={this.handleChange}
                                                                                        placeholder={form.description}
                                                                                    >
                                                                                    </Input>
                                                                                </div>
                                                                            );
                                                                        }

                                        else if(this.state.marineCheck && form?.name=='Sum_Insured'){
                                            return (
                                                <div className="form-group">
                                                <label className={form.mandatory === "MANDATORY" ? "required" : ""}>{form.description}</label>
                                                <Input
                                                  type="number"
                                                  required= {form.mandatory ? true : false}
                                                  name={form.name}
                                                  bsSize="sm"
                                                  placeholder={form.description}
                                                  disabled={true}
                                                  value={this.state.Sum_Insured?this.state.Sum_Insured:''}
                                                >
                                                </Input>
                                                </div>
                                            );
                                        }
                                        else if((this.state.isLegalService || this.state.oldRateLegal) && form.name === "Subscription_Detail"){
                                            return (
                                                <div className="form-group">
                                                <label className={form.mandatory === "MANDATORY" ? "required" : ""}>{form.description}</label>
                                                <Input
                                                  type="text"
                                                  required= {form.mandatory ? true : false}
                                                  name={form.name}
                                                  placeholder={form.description}
                                                  disabled={true}
                                                  value={this.state.Subscription_Detail?this.state.Subscription_Detail:''}
                                                >
                                                </Input>
                                                </div>
                                            );
                                        }
                                        else if( form.name === "Legal +" && (this.state.isLegalService || this.state.oldRateLegal)){
                                            return (
                                                <div className="form-group">
                                                <label className={form.mandatory === "MANDATORY" ? "required" : ""}>{form.name === "Legal +"? "Product" : form.description}</label>
                                                <Input
                                                  type="text"
                                                  required= {form.mandatory ? true : false}
                                                  name={form.name}
                                                  placeholder={this.state.dpiLegal? "DPI-Legal+" : form.description}
                                                  disabled={true}
                                                  value={this.state.legalPlus?this.state.legalPlus:''}
                                                >
                                                </Input>
                                                </div>
                                            );
                                        }
                                        else if( form.name === "LEAGL+ _FOR _HOSPITALS" && (this.state.isHospitalService)){
                                            return (
                                                <div className="form-group">
                                                <label className={form.mandatory === "MANDATORY" ? "required" : ""}>{form.description}</label>
                                                <Input
                                                  type="text"
                                                  required= {form.mandatory ? true : false}
                                                  name={form.name}
                                                  placeholder={form.description}
                                                  disabled={true}
                                                  value={this.state.LegalPlusHospitals?this.state.LegalPlusHospitals:''}
                                                >
                                                </Input>
                                                </div>
                                            );
                                        }
                                        else if( form.name === "Blawblrrrcngr_No" && this.state.radioVal === "corporate"){
                                            return (
                                                <div className="form-group">
                                                <label className={form.mandatory === "MANDATORY" ? "required" : ""}>{form.description}</label>
                                                <Input
                                                  type="text"
                                                  required= {false}
                                                  name={form.name}
                                                  placeholder={form.description}
                                                  onChange={this.handleChange}
                                                >
                                                </Input>
                                                </div>
                                            );
                                        }
                                        else if (this.state.windowUrl.includes("Joeans-Logistics-Bajaj-Specific-Marine") && form.name === "Policy_Start_Date") {
                                            return (
                                                <DatePicker
                                                    key={i}
                                                    name={form.name}
                                                    label={form.description}
                                                    placeholder={form.description}
                                                    required={form.mandatory}
                                                    className={
                                                        form.mandatory === "MANDATORY" ? "required" : ""
                                                    }
                                                    value={this.state.sendCalcData.Journey_Date || form.inputValue.split("/").reverse().join("-")}
                                                    disabled={true}
                                                />
                                            )
                                        }
                                        else if(form.name === "Party_State_Name" && this.state.windowUrl.includes("Joeans-Logistics-Bajaj-Specific-Marine")){
                                            return (
                                            <div className="form-group">
                                                <label className={form.mandatory === "MANDATORY" ? "required" : ""}>{form.description}</label>
                                                <Input
                                                    type="select"
                                                    required= {form.mandatory ? true : false}
                                                    name={form.name}
                                                    bsSize="sm"
                                                    onChange={this.handleChange}
                                                >
                                                <option value="">Select {form.description}</option>
                                                {this.state.stateList.map((item, i) => (
                                                    <option key={i} value={item.STATE}>
                                                    {item.STATE}
                                                    </option>
                                                ))}
                                                </Input>
                                            </div>
                                            )
                                        }
                                        else{
                                            if((form.name === "Invoice_Number" && this.state.isPartnerAgraga) || form.name === "Party_State_Name" && this.state.windowUrl.includes("Joeans-Logistics-Bajaj-Specific-Marine")) return 
                                            else {
                                                return (
                                                    <InputTextField
                                                        type={"text"}
                                                        key={i}
                                                        name={form.name}
                                                        label={form.description}
                                                        placeholder={form.description === 'GST' ? 'Eg. 123456789ABCDEA' : form.description}
                                                        required={form.mandatory}
                                                        disable={form.inputBy}
                                                        prefilled={form.inputValue === "1 Year" ? "" : form.inputValue}
                                                        handleChange={this.handleChange}
                                                        className={
                                                            (form.name === "Number_Of_Containers" && this.state.sendCalcData.Containerized === "Yes") ? "required":
                                                            form.mandatory === "MANDATORY" ? "required" : ""
                                                        }
                                                        maxLength={form?.name == "Pan_Number"?10:null}
                                                    />
                                                );
                                            }
                                        }
                                }
                                    if (
                                        form?.inputType === "PERCENT" &&
                                        form?.display === "YES"
                                    ) {
                                        return (
                                            <InputTextField
                                                type={"text"}
                                                key={i}
                                                name={form.name
                                                    
                                                }
                                                label={form.description}
                                                placeholder={form.description}
                                                required={form.mandatory}
                                                handleChange={this.handleChange}
                                                className={
                                                    form.mandatory === "MANDATORY" ? "required" : ""
                                                }
                                            />
                                        );
                                    }
                                    if (
                                        form?.inputType === "CHECKBOX" &&
                                        form?.display === "YES"
                                    ) {
                                        return (
                                            <div style={{ paddingLeft: "20px", }}>
                                                <InputCheckboxField
                                                    type={"checkbox"}
                                                    key={i}
                                                    name={form.name}
                                                    placeholder={form.name}
                                                    required={form.mandatory}
                                                    handleChange={this.handleChange}
                                                    className={
                                                        form.mandatory === "MANDATORY" ? "required" : ""
                                                    }
                                                />
                                                <p style={{ fontSize: "12px", }}>{form.inputValue}</p>
                                            </div>
                                        );
                                    }
                                    if ((this.state.marineCheck || this.state.isDCA || this.state.isLegalService || this.state.oldRateLegal || this.state.isMedicalEstablishment) && form.inputType === "FILE" && form.display === "YES") {
                                        if(form.name === "Invoice_FilePath" && this.state.isPartnerAgraga) return
                                        else {
                                            return (
                                                <InputTextField
                                                    type={"file"}
                                                    key={i}
                                                    name={form.name}
                                                    label={form.description}
                                                    required={form.mandatory}
                                                    disable={form.inputBy}
                                                    prefilled={form.inputValue}
                                                    handleChange={this.handleFiles}
                                                    className={
                                                        form.mandatory === "MANDATORY" ? "required" : ""
                                                    }
                                                />
                                            );
                                        }
                                    }
                            })}
                            {this.state.dpiLegal?
                                <>
                                    <div className="form-group">
                                        <label className="heading required">Who are you buying for?</label>
                                        <div className="radio-box pt-2">
                                            <input type="radio" id="Individual Practice" name="buyType" value="Individual Practice" onChange={(e)=>this.handleRadioChangeLegal(e)} checked={this.state.buyType==="Individual Practice"?true:false}/>
                                            <label for="invoice">Individual Practice</label>
                                            <input type="radio" id="hospital" name="buyType" value="Hospital/Nursing/Polyclinic" onChange={(e)=>this.handleRadioChangeLegal(e)} checked={this.state.buyType==="Hospital/Nursing/Polyclinic"?true:false}/>
                                            <label for="BR">Hospital/Nursing/Polyclinic</label>
                                        </div>
                                    </div>

                                                                        {this.state.buyType === 'Individual Practice' ?
                                                                            <DropdownSelect
                                                                                name="specialization"
                                                                                label="Select Your Specialization"
                                                                                val={static_data.specializationList}
                                                                                handleChange={(e) => this.handleSelectChange(e)}
                                                                                value={this.state.specialization}
                                                                                className="required"
                                                                            /> :
                                                                            <div>
                                                                                <InputTextField
                                                                                    type="number"
                                                                                    name="opdValue"
                                                                                    label="No. of OPD Cases(Annually)"
                                                                                    handleChange={(e) => this.handleInputChange(e)}
                                                                                    value={this.state.opdValue}
                                                                                    className="required"
                                                                                />
                                                                                <InputTextField
                                                                                    type="number"
                                                                                    name="ipdValue"
                                                                                    label="No. of IPD Cases(Annually)"
                                                                                    handleChange={(e) => this.handleInputChange(e)}
                                                                                    value={this.state.ipdValue}
                                                                                    className="required"
                                                                                />
                                                                            </div>
                                                                        }
                                                                        {this.state.specialization === 'Anesthetist' ?
                                                                            <DropdownSelect
                                                                                name="subSpecialization"
                                                                                label="Select Your Sub Specialization"
                                                                                val={static_data.subSpecializationAnesthetistList}
                                                                                handleChange={(e) => this.handleSelectChange(e)}
                                                                                value={this.state.subSpecialization}
                                                                                className="required"
                                                                            /> :
                                                                            this.state.specialization === 'Radiologist' ?
                                                                                <DropdownSelect
                                                                                    name="subSpecialization"
                                                                                    label="Select Your Sub Specialization"
                                                                                    val={static_data.subSpecializationRadiologist}
                                                                                    handleChange={(e) => this.handleSelectChange(e)}
                                                                                    value={this.state.subSpecialization}
                                                                                    className="required"
                                                                                /> :
                                                                                this.state.specialization === 'General Physician' ?
                                                                                    <DropdownSelect
                                                                                        name="subSpecialization"
                                                                                        label="Select Your Sub Specialization"
                                                                                        val={static_data.subSpecializationPGeneralPhysician}
                                                                                        handleChange={(e) => this.handleSelectChange(e)}
                                                                                        value={this.state.subSpecialization}
                                                                                        className="required"
                                                                                    /> :
                                                                                    this.state.specialization === 'Pathologist' ?
                                                                                        <DropdownSelect
                                                                                            name="subSpecialization"
                                                                                            label="Select Your Sub Specialization"
                                                                                            val={static_data.subSpecializationPathologist}
                                                                                            handleChange={(e) => this.handleSelectChange(e)}
                                                                                            value={this.state.subSpecialization}
                                                                                            className="required"
                                                                                        /> : null
                                                                        }
                                                                        <DropdownSelect
                                                                            name="limitOfIndemnity"
                                                                            label="Sum Insured"
                                                                            val={this.state.buyType === 'Individual Practice' ? static_data.limitOfIndemnityList : static_data.limitOfIndemnityHospitalList}
                                                                            handleChange={(e) => this.handleSelectChange(e)}
                                                                            value={this.state.limitOfIndemnity}
                                                                            className="required"
                                                                        />

                                                                        <DropdownSelect
                                                                            name="territory"
                                                                            label="Territory & Jurisdiction"
                                                                            val={static_data.territoryAndJusictionList}
                                                                            handleChange={(e) => this.handleSelectChange(e)}
                                                                            value={this.state.territory}
                                                                            disabled={true}
                                                                            className="required"
                                                                        />

                                                                        <label className="required">Select City</label>
                                                                        <Input
                                                                            type="select"
                                                                            required={true}
                                                                            onChange={this.handleChange}
                                                                            label="City"
                                                                            name="userSelectedCity"
                                                                            bsSize="md"
                                                                            value={this.state.userSelectedCity}
                                                                            className="mb-4"
                                                                        >
                                                                            <option value="" >Select</option>
                                                                            {this.state.userCity.map((ele, id) => (
                                                                                <option key={id} value={ele}>{ele}</option>
                                                                            ))}
                                                                        </Input>

                                                                        <label className="required">Assigned RM</label>
                                                                        <Input
                                                                            type="select"
                                                                            required={true}
                                                                            onChange={this.handleRmNameChange}
                                                                            label="RM NAME"
                                                                            name="RM_NAME"
                                                                            bsSize="md"
                                                                            value={this.state.RM_NAME}
                                                                            className="mb-4"
                                                                        >
                                                                            <option value="" >Select</option>
                                                                            {this.state.assignedRmList && this.state.assignedRmList.length > 0 && this.state.assignedRmList.map((ele, id) => (
                                                                                <option key={id} value={ele.id}>{ele.name}</option>
                                                                            ))}
                                                                        </Input>
                                                                    </> : null
                                                                }
                                                                {this.state.insurerName !== 'Sanctum' ?
                                                                    <div style={{ paddingLeft: "20px", paddingBottom: "10px" }}>
                                                                        <input type="checkbox" name="terms" className="form-check-input" required />
                                                                        <span style={{ fontSize: "12px" }}>I hereby agree to the <a href="https://securenow.in/terms-conditions/" target="_blank">terms & conditions</a> of the purchase of this policy.</span>
                                                                    </div> : null}
                                                                <div style={{ paddingLeft: "20px", paddingBottom: "10px" }}>
                                                                    <input type="checkbox" name="terms" className="form-check-input" required />
                                                                    <span style={{ fontSize: "12px" }}>I hereby agree to the <a href="https://static-files-website.s3.ap-south-1.amazonaws.com/terms+and+conditions/SecureNow+App+terms.pdf" target="_blank">terms & conditions</a> of the Securenow app.</span>
                                                                </div>
                                                                {/* <Button
                                        color="success"
                                        className="mb-4 mt-4"
                                        onClick={() => this.handlePremiumFormSubmit('premium')}
                                    >
                                        Calculate Premium
                                   </Button> */}

                                                                <p className="error">{this.state.errorMessage}</p>
                                                                {this.state.showPremium && !this.state.dpiLegal ? (
                                                                    <Button
                                                                        color="primary"
                                                                        type="submit"
                                                                        className="mb-4 mt-4"
                                                                        name="PREMIUM"
                                                                        onClick={() => this.setState({ btnType: "PREMIUM" })}
                                                                    >
                                                                        {(this.state.isLegalService || this.state.oldRateLegal) ? " Subscription Amount" : "Show Premium"}
                                                                    </Button>
                                                                    // <PolicyPremium
                                                                    //     isOpen={false}
                                                                    //     title={"Do You want to confirm your Premium ?"}
                                                                    //     buttonLabel={"Show Premium"}
                                                                    //     bodyText={
                                                                    //         "Your Premium is:" + this.state.calcPremium.premium
                                                                    //     }
                                                                    //     onClick={() => this.handlePremiumFormSubmit()}
                                                                    //     formData={false}
                                                                    //     disable={this.state.disable}
                                                                    //     cal={"YES"}
                                                                    // />
                                                                ) : (
                                                                    ""
                                                                )}
                                                                
                                                                {this.state.dpiLegal || ((this.state.fhiCheck || this.state.topUpCheck || this.state.seniorCheck) && !this.state.enableButtonForFhi) ? null : 
                                                                this.state.loading?<Button
                                                                color="success"
                                                                className="mb-4 ml-3 mt-4"
                                                                name="BUY"
                                                                disabled={ true}
                                                                >
                                                                Loading...
                                                                </Button>:
                                                                <Button
                                                                    color="success"
                                                                    type="submit"
                                                                    className="mb-4 ml-3 mt-4"
                                                                    name="BUY"
                                                                    onClick={() => this.setState({ btnType: this.state.insurerName == 'Sanctum' ? 'CREATE' : 'Buy' })}
                                                                    disabled={this.state?.disableBuyBtn ? true : false}
                                                                >
                                                                    Buy
                                                                </Button>}

                                    {this.state.dpiLegal? 
                                        <Button
                                        color="primary"
                                        type="submit"
                                        className="mb-4 mt-4"
                                        name="PREMIUM"
                                        onClick={(e) => this.handleDpiPremiumFormSubmit(e)}
                                        >
                                    Proceed
                                    </Button>:null}
                                {this.state.showCalculatedPremium && this.state.calcPremium && this.state.calcPremium.premium>-1 && !this.state.isLegalService && !this.state.oldRateLegal?
                                    <p>Your Premium is: <strong>{this.state.calcPremium.premium} + {this.state.isMedicalEstablishment ? (this.calculateGst(this.state.calcPremium.premium)+"(GST)") : ""}</strong></p>
                                    : null
                                }
                                 {(this.state.isLegalService || this.state.oldRateLegal) && this.state.showCalculatedPremium && this.state.calcPremium && this.state.calcPremium.premium>-1 && !this.state.dpiLegal?
                                    <p>Your Subscription Amount is: <strong>{this.state.calcPremium.premium + "+" + (this.calculateGst(this.state.calcPremium.premium)+"(GST)")}</strong></p>
                                    : null
                                }

                                                                {this.state.fhiCheck || this.state.seniorCheck || this.state.topUpCheck && this.state.showModal ?
                                                                    <Modal isOpen={this.state.modal}>
                                                                        <ModalBody>
                                                                            <div className='row' style={{ fontSize: "13px", fontWeight: "normal" }}>
                                                                                <div className='container text-center' style={{ fontSize: "15px", color: '#0B2E56', marginBottom: "10px", fontWeight: "bold" }} >Good Health Declaration Terms And Conditions</div>
                                                                                <div className="container">
                                                                                    <p> a) You have not declared that you are in good health. Please await a call from the insurer at your contact number provided.  </p>
                                                                                    <p> b) The insurer will understand your health condition and confirm the way forward. The insurer's call and next steps can take up to fifteen working days.</p>
                                                                                    <p> c) Please note these steps will only happen if you successfully pay the premium that was indicated to you. In case the insurer rejects your policy, the premium amount you've paid will be fully refunded to your payment mode.</p>
                                                                                    <p> d) You may choose to click Proceed and pay your premium if you agree with the above, or else go back or exit"</p>
                                                                                    <div className="col-md-12 text-center">
                                                                                        <Button outline color="danger" className="ml-4" onClick={this.hideModal}>Cancel</Button>
                                                                                        <Button outline color="success" className="ml-4" onClick={this.handlePremiumFormSubmit}>Submit</Button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </ModalBody>
                                                                    </Modal> : null}

                                                                <ToastContainer autoClose={8000} />

                                                                {/* {this.state.premiumpur ? (
                                        <PolicyPremium
                                            isOpen={false}
                                            title={"Premium Details"}
                                            buttonLabel={"Premium Details"}
                                            bodyText={
                                                "Your Premium is:" + this.state.calcPremium.premium
                                            }
                                            formData={false}
                                            disable={this.state.disable}
                                        />
                                    ) : (
                                            ""
                                        )} */}
                                                            </>}
                                                </CardBody>
                                            </Form>
                                        </Card>
                                    </Col>
                                </div> :
                            <LoadingIndicator />}
                    </>}
            </>
        );
    }
}

export default ProposalForm;
