import React from 'react';
import './loading.css';

const LoadingIndicator = () => (
  <div className="loading-container">
    <div className="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
    <p>Please wait while the COI files are being sent</p>
  </div>
);

export default LoadingIndicator;
