import React, { useEffect, useRef, useState } from "react";
import DownChevron from '../../assets/img/helper_Images/down-chevron.png'
import upChevron from '../../assets/img/helper_Images/up-chevron.png'
import "./CustomSelectField.css";

const CustomSelectField = ({
  optionsList = [],
  handleOptionSelect,
  label,
  Name,
  disabled,
  required
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("")
  const ref = useRef(null)
 
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowDropDown(false)
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const toggleDropdown = () => {
    setShowDropDown(!showDropDown);
  };

  const handleOptionClick=(Name, id, name)=>{
    setSelectedOption(name)
    handleOptionSelect(Name, id, name)
    setShowDropDown(false)
  }

  return (
    <div className="custom-select-new mb-3" ref={ref}>
      <label className={required? "required label" : "label"}>{label}</label>
      <div className="input-box d-flex justify-content-between" onClick={toggleDropdown}>
        <span title={selectedOption} classNameselected-text>{selectedOption ? selectedOption : "Select"}</span>
        <span className="arrow">{showDropDown ? <img src={upChevron} height="12px" width="10px"/> : <img src={DownChevron} height="12px" width="10px"/>}</span>
      </div>
      {showDropDown && (
        <ul className="dropdown-list">
          {optionsList.length > 0 &&
            optionsList.map(({ id, name }) => (
              <li key={id} onClick={() => handleOptionClick(Name, id, name)}>
                {name}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};
export default CustomSelectField;
