import React from 'react'
import '../sub-components/multi-invoice-table.css'

const MultiInvoiceDataTable = ({ tableData = [], type, removeInvoiceTableData, handleViewFile}) => {
    return (
        <div className='multi-invoice'>
            {tableData && tableData?.length > 0 ?
                <table className="table-container">
                    <thead>
                        <tr>
                            <th>Invoice Number</th>
                            <th>Invoice Date</th>
                            <th>Invoice File</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableData.map((ele, ind) => (
                            <tr key={ind}>
                                <td>{ele.Invoice_Number}</td>
                                <td>{ele.Invoice_Date}</td>
                                <td>
                                    <div className='d-flex justify-content-between'>
                                    {type === "view" ? (
                                        <span
                                            onClick={() => handleViewFile(ele.Invoice_FilePath)}
                                            style={{ color: '#2d5fbf', cursor: 'pointer' }}
                                        >
                                            {typeof ele.Invoice_FilePath === "string" 
                                            ? ele.Invoice_FilePath 
                                            : ele.Invoice_FilePath?.name || ''}
                                        </span>
                                        ) : (
                                        <span>
                                            {typeof ele.Invoice_FilePath === "string" 
                                            ? ele.Invoice_FilePath 
                                            : ele.Invoice_FilePath?.name || ''}
                                        </span>
                                        )
                                    }
                                    {type === "edit" ?
                                        <span style={{ cursor: ' pointer' }} onClick={() => removeInvoiceTableData(ind)}>
                                            <i style={{ color: 'red' }} className="fa fa-trash-o" aria-hidden="true"></i>
                                        </span>:null}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table> : null}
        </div>
    )
}

export default MultiInvoiceDataTable
