import React, { useState } from 'react'
import DropdownSelect from '../../../Inputs/SelectField'
import InputTextField from '../../../Inputs/InputField'
import { Button } from 'reactstrap'
import axios from 'axios'

const LegalAllInOneStepOne = ({ inputData = [], updateStep, insurerName }) => {
    const [formDetails, setFormDetails] = useState({})
    const [errMessage, setErrMessage] = useState('')

    const handleChange = (e) => {
        const temp = { ...formDetails }
        temp[e.target.name] = e.target.value
        setFormDetails(temp)
        setErrMessage('')
    }

    const validateBeforeSubmit = () => {
        let err = ""
        if (!formDetails.product) {
            err = "Please select product"
        }
        else if (!formDetails.subscription_period) {
            err = "Please select subscription period"
        }
        else if (!formDetails.subscription_provided) {
            err = "Please select subscription provided"
        }
        else if (!formDetails.leadType) {
            err = "Please select lead type"
        }
        else if (!formDetails.rate) {
            err = "Please enter rates"
        }
        return err
    }

    const handleSaveAndNext = (e) => {
        e.preventDefault()
        let isValid = validateBeforeSubmit()
        if (isValid) {
            setErrMessage(isValid)
        }
        else {
            const path = window.location.href
            let urlName = path.split("proposal-form/")[1]
            let url = `/web/url-name/${urlName}?Client_Type=next&product=${formDetails.product}&subscription_period=${formDetails.subscription_period}&subscription_provided=${formDetails.subscription_provided}&lead_type=${formDetails.leadType}&rate=${formDetails.rate}`
            axios.post(process.env.REACT_APP_BASE_URL + url)
                .then(res => {
                    console.log(res)
                    updateStep(2, res)
                })
                .catch((err) => console.log(err))
        }
    }

    return (
        <div className='row mt-5'>
            <div className='col-4'></div>
            <div className='col-4'>
                <div className='container bg-white' style={{ borderRadius: '12px' }}>
                    <div className='row'>
                        <div className='col-12 text-center'>
                            <img className='text-center' src={'https://static-files-website.s3.ap-south-1.amazonaws.com/securenow_logo.svg'} alt="SecureNow" style={{ padding: "20px 0" }} />
                        </div>
                    </div>
                    <h4 className='text-center mb-3'>Proposal Form for <br /> {insurerName}</h4>
                    {inputData.length > 0 && inputData.map((form, i) => {
                        if ((form?.inputType === "VARCHAR-DROPDOWN") && form?.display === "YES") {
                            return (
                                <DropdownSelect
                                    key={i}
                                    name={form.name}
                                    label={form.description}
                                    placeholder={form.description}
                                    required={form.mandatory === "MANDATORY"}
                                    handleChange={handleChange}
                                    val={form?.inputValue}
                                    selectname={form.description}
                                    className={form.mandatory === "MANDATORY" ? "required" : ""}
                                    isFlag={"dynamic"}
                                />
                            )
                        }
                        else if (form?.inputType === "VARCHAR" && form?.display === "YES") {
                            return (
                                <InputTextField
                                    type={"text"}
                                    key={i}
                                    name={form.name}
                                    label={form.description}
                                    placeholder={form.description === 'GST' ? 'Eg. 123456789ABCDEA' : form.description}
                                    required={form.mandatory}
                                    disable={form.inputBy}
                                    prefilled={form?.inputValue}
                                    handleChange={handleChange}
                                    className={
                                        form.mandatory === "MANDATORY" ? "required" : ""
                                    }
                                    maxLength={form?.name == "Pan_Number" ? 10 : null}
                                />
                            );
                        }
                    })}
                    <p className='text-danger text-center mb-2'>{errMessage}</p>
                    <Button
                        color="primary"
                        type="submit"
                        className="mb-4 mt-4"
                        onClick={handleSaveAndNext}
                        style={{ backgroundColor: '#f15a23', width: '100%', outline: 'none', border: 'none' }}
                    >Save & Next</Button>
                </div>
            </div>
            <div className='col-4'></div>
        </div>
    )
}

export default LegalAllInOneStepOne
